import React from 'react';
import PropTypes from 'prop-types';
import Header, { NavbarLink } from 'cccisd-header';

import headerBg from './headerBg.png';
import logo from './logo.png';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const NavBar = () => {
    const isStudent = Fortress.user.acting.role.handle === 'learner';

    const non3cUberDropdown = Fortress.user.acting.role.handle === 'uberadmin' && !Fortress.isSuperUser() && (
        <li className="dropdown">
            <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Admin <span className="caret" />
            </a>
            <ul className="dropdown-menu">
                <NavbarLink to="/quest" highlight="/quest">
                    Quests
                </NavbarLink>
                <NavbarLink to="/resources/admin" highlight="/resources/admin">
                    Resources
                </NavbarLink>
            </ul>
        </li>
    );

    return (
        <div className={style.header}>
            <div
                style={{ backgroundImage: `url(${headerBg})` }}
                className={`${style.topHeader} ${isStudent ? style.hideEditProfile : ''}`}
            >
                <Header
                    className="container-fluid"
                    logo={<img src={logo} alt="SEL Quest" className={style.logo} />}
                    noLogoLink={isStudent}
                >
                    {non3cUberDropdown}
                </Header>
            </div>
        </div>
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
