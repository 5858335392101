import React from 'react';
import PropTypes from 'prop-types';
import IconEnvelop from 'cccisd-icons/envelop';
import style from './style.css';
const hostName = window.location.hostname;

const Footer = props => {
    return (
        <div className={style.footer}>
            <div className={props.className}>
                <address className={style.address}>
                    <ul className="list-unstyled">
                        <li>
                            <b>
                                <IconEnvelop spaceRight />
                                Technical support:
                            </b>{' '}
                            <a href="mailto:support@3cisd.com">support@3cisd.com</a>
                        </li>
                        <li>
                            <b>
                                <IconEnvelop spaceRight />
                                Research support:
                            </b>{' '}
                            Akila Raoul, PI, at <a href="mailto:akila@xsel-labs.com">akila@xsel-labs.com</a>
                        </li>
                    </ul>
                </address>
                <div className={style.copyrightRow}>
                    <div>
                        Software developed by{' '}
                        <a href={`//www.3cisd.com?r=${hostName}`} rel="noopener noreferrer" target="_blank">
                            <u>3C Institute</u>.
                        </a>{' '}
                        &copy; {props.copyrightYear}, All Rights Reserved.
                    </div>
                    {props.showPolicyLinks && (
                        <div>
                            {props.showTermsLink && (
                                <a href="https://xsel-labs.com/terms-of-use" rel="noopener noreferrer" target="_blank">
                                    <u>Terms of Use</u>
                                </a>
                            )}
                            {props.showPolicyLink && (
                                <a href="https://xsel-labs.com/privacy" rel="noopener noreferrer" target="_blank">
                                    <u>Privacy Policy</u>
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showContactUsLink: PropTypes.bool,
    showTermsLink: PropTypes.bool,
    showPolicyLink: PropTypes.bool,
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
    showContactUsLink: true,
    showTermsLink: true,
    showPolicyLink: true,
};

export default Footer;
