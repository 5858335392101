import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './style.css';

const Radio = props => {
    return (
        <div className={style.radioWrapper} onClick={props.onChange}>
            <div className={classnames(style.radio, { [style.checked]: props.checked })} />
        </div>
    );
};

Radio.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Radio;
