import { useEffect } from 'react';

// helper component
const TrackValues = props => {
    const { values, setFieldValue } = props;

    useEffect(() => {
        if (!values.needMeasuresGeneration) {
            setFieldValue('needMeasuresGeneration', true);
        }
    }, [values.gradeLevels, values.selAreas]);

    return null;
};

TrackValues.propTypes = {};

export default TrackValues;
