import React from 'react';
import PropTypes from 'prop-types';
import _xor from 'lodash/xor';
import data, { respondentOptions } from '../data.js';
import style from './style.css';

const { gradeLevels } = data;

const Respondents = props => {
    const { values, setFieldValue } = props;

    return (
        <div className={style.wrapper}>
            {respondentOptions.map(item => (
                <div key={item.value}>
                    <div className={style.title}>{item.label}</div>
                    <div className={style.list}>
                        {gradeLevels.map(gradeLevel => (
                            <div key={gradeLevel.code} className="checkbox" style={{ margin: 0 }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={gradeLevel.code}
                                        checked={values.respondents[item.value].includes(gradeLevel.code)}
                                        onChange={() => {
                                            setFieldValue('respondents', {
                                                ...values.respondents,
                                                [item.value]: _xor(values.respondents[item.value], [gradeLevel.code]),
                                            });
                                            setFieldValue('needMeasuresGeneration', true);
                                        }}
                                    />{' '}
                                    {gradeLevel.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

Respondents.propTypes = {
    stageInfo: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Respondents;
