import React, { useState, useMemo } from 'react';
import { Formik, Form } from 'cccisd-formik';
import AssessmentType from './AssessmentType';
import Purpose from './Purpose';
import SkillsSelector from './SkillsSelector';
import Duration from './Duration';
import Measures from './Measures';
import Schedule from './Schedule';
import Respondents from './Respondents';
import Modal from 'cccisd-modal';
import Results from './Results';
import Summary from './Summary';
import _pick from 'lodash/pick';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import TrackValues from './TrackValues';
import IconPrinter from 'cccisd-icons/printer';
import style from './style.css';
import LandingPage from './LandingPage';
import IconCancel from 'cccisd-icons/cross4';

const Fortress = window.cccisd.fortress;

const stages = [
    {
        code: 'assessmentType',
        label: 'Type',
        number: 1,
        question: 'Select the type of SEL assessment',
        component: AssessmentType,
        errorFields: ['assessmentType'],
    },
    {
        code: 'selSkills',
        label: 'SEL Skills',
        number: 1,
        question: 'What SEL area(s) would you like to assess?',
        component: SkillsSelector,
        errorFields: ['selAreas'],
        skip: values => values.assessmentType !== 'detailed',
    },
    {
        code: 'purpose',
        label: 'Purpose',
        number: 2,
        question: 'Select the purpose of the SEL assessment',
        component: Purpose,
        errorFields: ['purpose'],
    },
    {
        code: 'schedule',
        label: 'Timepoints',
        number: 2,
        question: 'When would you like to conduct the assessment?',
        component: Schedule,
        errorFields: ['timepoints'],
    },
    {
        code: 'respondents',
        label: 'Respondents',
        number: 3,
        question: 'Who should complete the SEL assessment?',
        component: Respondents,
        errorFields: ['respondents'],
    },
    {
        code: 'durations',
        label: 'Time Spent',
        number: 4,
        question: 'How much time should it take to complete the assessment?',
        description: '* Dedicating more time to assessment will provide more comprehensive results',
        component: Duration,
        errorFields: ['durations'],
    },
    {
        code: 'measures',
        label: 'Measures',
        number: 5,
        question: 'What measures would you like to include in your assessment?',
        component: Measures,
        errorFields: ['measures'],
    },
    {
        code: 'results',
        label: 'Results',
        number: 6,
        component: Results,
        errorFields: [],
    },
    {
        code: 'summary',
        label: 'Summary',
        number: 7,
        component: Summary,
        errorFields: [],
    },
];

const validate = values => {
    const errors = {};

    const actualRespondents = Object.entries(values.respondents)
        .filter(([key, value]) => value.length > 0)
        .map(([key]) => key);

    const currentDate = (() => {
        let date = new Date();
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);
        return date.toISOString().split('T')[0];
    })();

    if (!values.assessmentType) {
        errors.assessmentType = 'You have to pick an assessment type';
    }

    if (!values.purpose) {
        errors.purpose = 'You have to pick the purpose';
    }

    if (actualRespondents.length === 0) {
        errors.respondents = 'You have to pick at least one respondent group';
    }

    if (values.selAreas.length === 0) {
        errors.selAreas = 'You have to pick at least one area';
    }

    if (
        !actualRespondents.every(respondent => {
            const value = values.durations[respondent];
            return typeof value === 'number' && value > 0 && value < 100;
        })
    ) {
        errors.durations = 'You have to pick time';
    }

    if (!values.timepoints.every(item => item.title)) {
        errors.timepoints = 'All timepoints require a title';
    }
    if (!errors.timepoints && !values.timepoints.every(item => item.openDate && item.closeDate)) {
        errors.timepoints = 'All dates are required';
    }
    if (!errors.timepoints && !values.timepoints.every(item => item.openDate < item.closeDate)) {
        errors.timepoints = 'Open date should be before the close date';
    }
    if (!errors.timepoints && !values.timepoints.every(item => item.openDate >= currentDate)) {
        errors.timepoints = 'All dates should be in the future';
    }
    if (!errors.timepoints && values.timepoints.length > 1) {
        const sortedTimepoints = [...values.timepoints].sort((a, b) => a.openDate.localeCompare(b.openDate));
        if (
            !sortedTimepoints.every((item, index) => {
                if (index === 0) {
                    return true;
                }

                return item.openDate > sortedTimepoints[index - 1].closeDate;
            })
        ) {
            errors.timepoints = 'Timepoint dates should not overlap with another timepoint';
        }
    }

    return errors;
};

const AssessmentBuilder = props => {
    const [currentStageCode, setCurrectStageCode] = useState('assessmentType');
    const [stepError, setStepError] = useState(null);
    const [showLandingPage, setShowLandingPage] = useState(true);

    const currentStage = useMemo(() => {
        const index = stages.findIndex(item => item.code === currentStageCode);
        return {
            ...stages[index],
            index,
        };
    }, [currentStageCode]);

    const getPrevStage = values => {
        let found;
        for (let i = currentStage.index - 1; i >= 0; i--) {
            if (stages[i].skip && stages[i].skip(values)) {
                continue;
            }
            found = stages[i];
            break;
        }

        return found || null;
    };

    const getNextStage = values => {
        let found;
        for (let i = currentStage.index + 1; i < stages.length; i++) {
            if (stages[i].skip && stages[i].skip(values)) {
                continue;
            }
            found = stages[i];
            break;
        }

        return found || null;
    };

    const onSubmit = values => {
        if (currentStage.errorFields) {
            const errors = Object.values(_pick(validate(values), currentStage.errorFields));
            if (errors.length > 0) {
                showError(errors[0]);
                return;
            }
        }

        notification('Demo Completed');
    };

    const showError = text => {
        setStepError(text);
        setTimeout(() => setStepError(null), 3000);
    };

    const goToNextStep = values => {
        if (currentStage.errorFields) {
            const errors = Object.values(_pick(validate(values), currentStage.errorFields));
            if (errors.length > 0) {
                showError(errors[0]);
                return;
            }
        }

        setCurrectStageCode(getNextStage(values).code);
    };

    const Component = currentStage.component;

    const initialValues = {
        assessmentType: '',
        selAreas: [],
        purpose: '',
        timepoints: [],
        respondents: { students: [], teachers: [] },
        durations: { students: 0, teachers: 0 },
        measures: {},
        needMeasuresGeneration: true,
    };

    const buildNew = () => {
        setShowLandingPage(false);
    };
    if (showLandingPage) {
        return <LandingPage buildNew={buildNew} />;
    }
    return (
        <div className={style.form}>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ errors, values, setFieldValue }) => {
                    const prevStage = getPrevStage(values);
                    const nextStage = getNextStage(values);

                    return (
                        <Form>
                            <TrackValues values={values} setFieldValue={setFieldValue} />
                            <div className={style.questionWrapper}>
                                <div className={style.number}>{currentStage.number}</div>
                                <div>
                                    <div className={style.labelWrapper}>
                                        <div className={style.label}>
                                            {currentStage.label}
                                            {currentStage.question ? ':' : ''}
                                        </div>
                                        {currentStage.question ? (
                                            <div className={style.question}>{currentStage.question}</div>
                                        ) : null}
                                    </div>
                                    {currentStage.description && (
                                        <div className={style.description}>{currentStage.description}</div>
                                    )}
                                </div>
                            </div>

                            {Component && (
                                <div
                                    className={style.content}
                                    style={{
                                        height: Fortress.auth() ? 'calc(100vh - 24em)' : 'calc(100vh - 21em)',
                                    }}
                                >
                                    <Component stageInfo={currentStage} values={values} setFieldValue={setFieldValue} />
                                </div>
                            )}

                            <div className={style.buttonWrapper}>
                                <button className="btn btn-link" type="button" onClick={() => setShowLandingPage(true)}>
                                    <IconCancel spaceRight />
                                    Close Assessment Builder
                                </button>
                                <div>
                                    {prevStage ? (
                                        <button
                                            type="button"
                                            className={'btn btn-primary ' + style.prevStage}
                                            onClick={() => setCurrectStageCode(prevStage.code)}
                                        >
                                            Back: {prevStage.label}
                                        </button>
                                    ) : (
                                        <div />
                                    )}
                                    {!nextStage && (
                                        <Modal
                                            trigger={
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    style={{ marginRight: '0.5em' }}
                                                >
                                                    <IconPrinter spaceRight />
                                                    Print
                                                </button>
                                            }
                                            title="Print"
                                            size="large"
                                        >
                                            <div>Here will be the printable summary.</div>
                                        </Modal>
                                    )}
                                    {(() => {
                                        const nextButton = nextStage ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => goToNextStep(values)}
                                            >
                                                Next: {nextStage.label}
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => onSubmit(values)}
                                            >
                                                Create Assessment
                                            </button>
                                        );

                                        return stepError ? (
                                            <Tooltip
                                                title={stepError}
                                                type="error"
                                                placement="top-right"
                                                tippyProps={{
                                                    trigger: 'manual',
                                                    showOnCreate: true,
                                                    hideOnClick: false,
                                                }}
                                            >
                                                {nextButton}
                                            </Tooltip>
                                        ) : (
                                            nextButton
                                        );
                                    })()}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    );
};

export default AssessmentBuilder;
