import React from 'react';
import PropTypes from 'prop-types';
import data from '../data.js';
import style from './style.css';

export const getResultText = values => {
    const totalTimepoints = values.timepoints.length;
    const code = `${values.assessmentType}-${values.purpose}`;
    const skills = data.skills
        .filter(skill => skill.list.some(item => values.selAreas.includes(item.code)))
        .map(skill => skill.label)
        .join(', ');

    const options = {
        'quick-snapshot': (
            <div>
                <p>
                    Thank you for using Assessment Builder! You created a <b>quick overview</b> assessment designed to
                    show a <b>snapshot</b> of current SEL skills.
                </p>
                <p>This assessment measures:</p>
                <ul>
                    <li>
                        <b>Overall SEL competence.</b> Results provide a broad view of SEL skills that can identify a
                        need for general SEL instruction or professional development. NOTE: No breakdown by{' '}
                        <span className={style.highlighted}>particular SEL</span> domain.
                    </li>
                    <li>
                        <b>Current SEL competence.</b> NOTE: No indication of how SEL competence changes with time.
                    </li>
                </ul>
                <p>If you would rather measure:</p>
                <ul>
                    <li>
                        <b>Specific SEL skills,</b> return to Step 1 and select &quot;Detailed View&quot;.
                    </li>
                    <li>
                        <b>Changes in SEL skills over time,</b> return to Step 2 and select &quot;Growth&quot;.
                    </li>
                </ul>
            </div>
        ),
        'quick-growth': (
            <div>
                <p>
                    Thank you for using Assessment Builder! You created a <b>quick overview</b> assessment designed to
                    show <b>growth</b> in SEL skills over time.
                </p>
                <p>This assessment measures:</p>
                <ul>
                    <li>
                        <b>Overall SEL competence.</b> Results provide a broad view of SEL skills that can identify a
                        need for general SEL instruction or professional development. NOTE: No breakdown by{' '}
                        <span className={style.highlighted}>particular SEL</span> domain.
                    </li>
                    <li>
                        <b>How SEL skills change over {totalTimepoints} time points.</b> Results can be used as evidence
                        of growth in SEL skills and effectiveness of the SEL curriculum and interventions.
                    </li>
                </ul>
                <p>If you would rather measure:</p>
                <ul>
                    <li>
                        <b>Specific SEL skills,</b> return to Step 1 and select &quot;Detailed View&quot;.
                    </li>
                    <li>
                        <b>SEL skills for a single timepoint,</b> return to Step 2 and select &quot;Snapshot&quot;.
                    </li>
                </ul>
            </div>
        ),
        'detailed-snapshot': (
            <div>
                <p>
                    Thank you for using Assessment Builder! You created a <b>detailed view</b> assessment designed to
                    show a <b>snapshot</b> of current SEL skills.
                </p>
                <p>This assessment measures:</p>
                <ul>
                    <li>
                        <b>Competence in the following areas: {skills}.</b> Results can be used to inform classroom
                        instruction or more targeted interventions.
                    </li>
                    <li>
                        <b>Current SEL competence.</b> NOTE: No indication of how SEL competence changes with time.
                    </li>
                </ul>
                <p>If you would rather measure:</p>
                <ul>
                    <li>
                        <b>Overall SEL competence,</b> return to Step 1 and select &quot;Quick Overview&quot;.
                    </li>
                    <li>
                        <b>Changes in SEL skills over time,</b> return to Step 2 and select &quot;Growth&quot;.
                    </li>
                </ul>
            </div>
        ),
        'detailed-growth': (
            <div>
                <p>
                    Thank you for using Assessment Builder! You created a <b>detailed view</b> assessment designed to
                    show <b>growth</b> in SEL skills over time.
                </p>
                <p>This assessment measures:</p>
                <ul>
                    <li>
                        <b>Competence in the following areas: {skills}.</b> Results can be used to inform classroom
                        instruction or more targeted interventions.
                    </li>
                    <li>
                        <b>How SEL skills change over {totalTimepoints} time points.</b> Results can be used as evidence
                        of growth in SEL skills and effectiveness of the SEL curriculum and interventions.
                    </li>
                </ul>
                <p>If you would rather measure:</p>
                <ul>
                    <li>
                        <b>Overall SEL competence,</b> return to Step 1 and select &quot;Quick Overview&quot;.
                    </li>
                    <li>
                        <b>SEL skills for a single timepoint,</b> return to Step 2 and select &quot;Snapshot&quot;.
                    </li>
                </ul>
            </div>
        ),
    };

    return options[code];
};

const Results = props => {
    const { values } = props;

    return <div>{getResultText(values)}</div>;
};

Results.propTypes = {
    stageInfo: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Results;
