import React, { useState, useEffect } from 'react';

import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { NavLink, useLocation } from 'cccisd-react-router';
import Tabs from 'cccisd-tabs';
import style from './style.css';
import ProgressTable from './ProgressTable';
import assignmentPlanQuery from './graphql/assignmentPlanList.graphql';
import assignmentPlanByDistrictQuery from './graphql/assignmentPlanListByDistrict.graphql';
import assignmentPlanTeachersQuery from './graphql/assignmentPlanListTeachers.graphql';
import timepointsQuery from './graphql/timepoints.graphql';
import deploymentIdQuery from './graphql/deploymentId.graphql';
import Heading from '../../components/Heading';

const Fortress = window.cccisd.fortress;

const Progress = props => {
    const [assignmentPlanList, setAssignmentPlanList] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [timepoints, setTimepoints] = useState([]);
    const [noTimepoints, setNoTimepoints] = useState(false);
    const [currentTimepoint, setCurrentTimepoint] = useState(null);
    const [currentDeploymentId, setCurrentDeploymentId] = useState(null);
    const [loading, setLoading] = useState(true);
    const { hash } = useLocation();

    useEffect(() => {
        if (hash === '#studentsOverall') {
            setNoTimepoints(true);
        }
        async function fetchData() {
            try {
                const isUber = Fortress.user.acting.role.handle === 'uberadmin';
                const isTeacher = Fortress.user.acting.role.handle === 'instructor';

                let query = assignmentPlanByDistrictQuery;
                if (isUber) {
                    query = assignmentPlanQuery;
                } else if (isTeacher) {
                    query = assignmentPlanTeachersQuery;
                }

                const res = await client.query({
                    query,
                    fetchPolicy: 'network-only',
                    variables: {},
                });

                let plans = [];
                if (isUber) {
                    plans = _get(res, 'data.flows.assignmentPlanList', []) || [];
                } else if (isTeacher) {
                    plans = (_get(res, 'data.groups.classList', []) || []).map(c => c.selectedAssignmentPlan);
                } else {
                    plans = _get(res, 'data.groups.groupingUnit.createdAssignmentPlanList', []) || [];
                }

                // remove duplicates
                let noDuplicatePlans = {};
                plans.forEach(p => {
                    noDuplicatePlans[p.assignmentPlanId] = p;
                });

                const list = Object.values(noDuplicatePlans).map(plan => {
                    return {
                        value: plan.assignmentPlanId,
                        label: plan.label,
                    };
                });

                const sortedList = _sortBy(list, ['value']);

                setAssignmentPlanList(sortedList);

                if (Array.isArray(sortedList) && sortedList.length > 0) {
                    setCurrentPlan(sortedList[0].value);
                    await fetchTimepoints(sortedList[0].value);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (hash === '#studentsOverall') {
            setNoTimepoints(true);
        } else if (timepoints.length > 0) {
            setNoTimepoints(false);
            (async () => {
                await fetchDeploymentId([currentPlan], [currentTimepoint]);
            })();
        }
    }, [hash]);

    const fetchTimepoints = async assignmentPlanId => {
        try {
            const res = await client.query({
                query: timepointsQuery,
                fetchPolicy: 'network-only',
                variables: { assignmentPlanId },
            });

            const list = res.data.flows.assignmentSessionList.filter(session => session.timepoint);
            setTimepoints(list);

            const sortedList = list.length > 0 && getSortedTimepoints(list);

            if (sortedList) {
                setCurrentTimepoint(sortedList[0].value);
                await fetchDeploymentId([assignmentPlanId], [sortedList[0].value]);
            }

            if (list.length > 0 && hash !== '#studentsOverall') {
                setNoTimepoints(false);
            } else {
                setNoTimepoints(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchDeploymentId = async (assignmentPlanId, sessionId) => {
        try {
            const res = await client.query({
                query: deploymentIdQuery,
                fetchPolicy: 'network-only',
                variables: { assignmentPlanId, sessionId },
            });

            const deploymentId = res.data.flows.assignmentSession.deploymentId;

            setCurrentDeploymentId(deploymentId);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    // on tabChange set deploymentId if null / on overalltab

    const onAssignmentChange = e => {
        setCurrentPlan(+e.target.value);
        setLoading(true);
        (async () => {
            await fetchTimepoints(+e.target.value);
        })();
    };

    const onTimepointChange = e => {
        setLoading(true);
        setCurrentTimepoint(+e.target.value);
        (async () => {
            await fetchDeploymentId([currentPlan], [+e.target.value]);
        })();
    };

    const getSortedTimepoints = list => {
        const sessionList = list.map(session => {
            return {
                value: session.sessionId,
                label: `Timepoint ${session.timepoint}`,
            };
        });

        const sortedList = _sortBy(sessionList, ['value']);
        return sortedList;
    };

    const renderSelectFields = () => {
        return (
            <div className={style.selectArea}>
                <div className={style.selectBox}>
                    <label>Assessments:</label>
                    <select className="form-control" value={currentPlan} onChange={onAssignmentChange}>
                        {assignmentPlanList.map(assignment => (
                            <option value={assignment.value} key={assignment.label}>
                                {assignment.label}
                            </option>
                        ))}
                    </select>
                </div>
                {!noTimepoints && (
                    <div className={style.selectBox}>
                        <label>Timepoint:</label>
                        <select className="form-control" value={currentTimepoint} onChange={onTimepointChange}>
                            {getSortedTimepoints(timepoints).map(timepoint => (
                                <option value={timepoint.value} key={timepoint.label}>
                                    {timepoint.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        );
    };

    const getTabs = () => {
        const tablist = props.tabs.map(tab => {
            const name = tab.handle;
            const title = tab.label;
            const content = (
                <ProgressTable
                    key={tab.handle}
                    tableProps={props[name]}
                    assignmentPlanId={currentPlan}
                    timepoint={currentTimepoint}
                    noTimepoints={noTimepoints}
                    deploymentId={currentDeploymentId}
                />
            );
            return { name, title, content };
        });
        return tablist;
    };

    if (loading) {
        return <Loader loading />;
    }

    if (assignmentPlanList && !currentPlan) {
        return (
            <div className="alert alert-warning">
                <p>
                    You must <NavLink to="/assessments">create an Assessment</NavLink> before viewing Progress
                </p>
            </div>
        );
    }

    return (
        <div>
            <Heading text="Progress" />
            {renderSelectFields()}
            {
                <div className={style.topSpace}>
                    <Tabs tabList={getTabs()} saveInHash />
                </div>
            }
        </div>
    );
};

export default Progress;
