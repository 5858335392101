/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import data, { respondentOptions } from '../data.js';
import AssessmentChoices from '../Measures/AssessmentChoices';
import IconCheck from 'cccisd-icons/checkmark';
import IconClock from 'cccisd-icons/clock';
import IconEarth from 'cccisd-icons/earth';
import IconUser from 'cccisd-icons/users4';
import IconClipboard from 'cccisd-icons/clipboard2';
import { getResultText } from '../Results';
import style from './style.css';

const gradeMap = data.gradeLevels.reduce((obj, item) => {
    obj[item.code] = item.label;
    return obj;
}, {});

const measureMap = data.measures.reduce((obj, item) => {
    obj[item.code] = item;
    return obj;
}, {});

const respondentMap = respondentOptions.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
}, {});

const languageMap = {
    en: 'English',
    es: 'Spanish',
};

const typeMap = {
    direct: 'Direct Assessment',
    selfReport: 'Self Report',
    survey: 'Survey',
};

const skillMap = data.skills.reduce((obj, item) => {
    obj[item.code] = item;
    return obj;
}, {});

const Review = props => {
    const { values } = props;

    const renderMeasures = (respondent, grade) => {
        const measures = values.measures[respondent]?.[grade] || [];
        const isSame = typeof measures === 'string';
        const duration = isSame
            ? 0
            : measures.reduce((total, measureCode) => total + measureMap[measureCode].duration, 0);

        return (
            <div key={grade} className={style.grade}>
                <div className={style.gradeHeader}>
                    <div className={style.title}>{gradeMap[grade]}</div>
                    {!isSame && (
                        <div className={style.time}>
                            Total estimated time: <IconClock spaceLeft spaceRight />
                            <strong>{duration} minutes</strong>
                        </div>
                    )}
                </div>
                <div className={style.measures}>
                    {isSame ? (
                        <div>The same as {gradeMap[measures]}</div>
                    ) : measures.length === 0 ? (
                        <div>There are currently no measures selected</div>
                    ) : (
                        measures.map((measureCode, index) => {
                            const measure = measureMap[measureCode];

                            return (
                                <div key={measureCode} className={style.measure}>
                                    <div className={style.content}>
                                        <div className={style.title}>
                                            {measure.pdf ? (
                                                <a href={measure.pdf} target="_blank" rel="noopener noreferrer">
                                                    {measure.label}
                                                </a>
                                            ) : (
                                                measure.label
                                            )}
                                        </div>
                                        <div className={style.grid}>
                                            <div>
                                                <IconClock spaceRight />
                                                {measure.duration} minutes
                                            </div>
                                            <div className={style.skill}>
                                                <div className={style.skillIcon} />
                                                {measure.area.map(a => skillMap[a].label).join(', ')}
                                            </div>
                                            <div>
                                                <IconEarth spaceRight />
                                                {measure.language.map(l => languageMap[l]).join(', ')}
                                            </div>
                                            <div>
                                                <IconClipboard spaceRight />
                                                {typeMap[measure.type]}
                                            </div>
                                            <div>
                                                <IconUser spaceRight />
                                                {respondentMap[measure.respondent]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        );
    };

    const renderRespondent = respondentInfo => {
        const respondent = respondentInfo.value;

        return (
            <div key={respondent} className={style.respondent}>
                <div className={style.respondentHeader}>
                    {respondentInfo.icon} {respondentInfo.label}
                </div>
                <div className={style.gradeWrapper} style={{ backgroundColor: respondentInfo.color }}>
                    {data.gradeLevels
                        .filter(level => values.respondents[respondent].includes(level.code))
                        .map(level => renderMeasures(respondent, level.code))}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={{ marginTop: '-2em' }}>
                <AssessmentChoices {...props} />
            </div>

            <div className={style.header}>
                <IconCheck spaceRight />
                Recommended Measures
            </div>

            <div className={style.respondentWrapper}>
                {respondentOptions.filter(item => values.respondents[item.value].length > 0).map(renderRespondent)}
            </div>

            <div className={style.header}>
                <IconCheck spaceRight />
                Results
            </div>
            <div style={{ marginLeft: '2em' }}>{getResultText(values)}</div>
        </div>
    );
};

Review.propTypes = {
    values: PropTypes.object,
};

export default Review;
