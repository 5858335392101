import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import Style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className="container" style={{ marginTop: '20px' }}>
            <div className="row">
                <div className="col-md-8">
                    <h1>Welcome to SELQuest</h1>
                    <p>
                        SEL Quest empowers educators with the tools and supports needed to effectively implement
                        social-emotional (SE) student assessments. SEL Quest offers an integrated suite of components to
                        facilitate each step of the assessment process, including:
                    </p>
                    <ul>
                        <li>
                            A searchable database of research-based SE assessments for K-12 grades with an interactive
                            &quot;Assessment Builder&quot; that helps educators select the most appropriate assessments
                            for their students
                        </li>
                        <li>
                            An easy-to-use platform for administering SE assessments and receiving concise and
                            actionable data reports
                        </li>
                        <li>
                            A resource center with detailed explanations of SE competencies and assessment formats, how
                            to use SEL Quest software, and guidance for interpreting data reports and applying insights
                            into instructional practice
                        </li>
                    </ul>
                    <p>
                        Sign in to get started! If you need technical assistance, please contact your site administrator
                        or 3C Institute at <a href="mailto:support@3cisd.com">support@3cisd.com</a>.
                    </p>
                </div>
                <div className="col-md-4">
                    {Fortress.auth() ? (
                        <div className={Style.welcomeBox}>
                            <p>Hello, {Fortress.user.username}</p>
                            {homepageRoute && (
                                <Link to={homepageRoute.url}>
                                    <button type="button" className="btn btn-primary">
                                        Go To Home
                                    </button>
                                </Link>
                            )}
                        </div>
                    ) : (
                        <LoginForm showPasswordToggle />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Welcome;
