import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import IconCheck from 'cccisd-icons/checkmark';
import { purposeOptions } from '../../Purpose';
import { assessmentTypeOptions } from '../../AssessmentType';
import data, { respondentOptions } from '../../data.js';
import style from './style.css';

const purposeMap = purposeOptions.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
}, {});

const assessmentTypeMap = assessmentTypeOptions.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
}, {});

const Measures = props => {
    const { values } = props;

    const skillSet = useMemo(() => {
        return data.skills
            .filter(skill => skill.list.some(item => values.selAreas.includes(item.code)))
            .map(skill => skill.label);
    }, [values.selAreas]);

    const actualRespondents = respondentOptions.filter(item => values.respondents[item.value].length > 0);

    return (
        <div>
            <div className={style.header}>
                <IconCheck spaceRight />
                Assessment Choices
            </div>
            <div className={style.choicesWrapper}>
                <div className={style.choice}>
                    <div className={style.number}>1</div>
                    <div>
                        {assessmentTypeMap[values.assessmentType]}
                        {values.assessmentType === 'detailed' && (
                            <div>
                                <b>
                                    {skillSet.length} domain{skillSet.length > 1 ? 's' : ''}:
                                </b>{' '}
                                {skillSet.join(', ')}
                            </div>
                        )}
                    </div>
                </div>
                <div className={style.choice}>
                    <div className={style.number}>2</div>
                    <div>
                        {purposeMap[values.purpose]}
                        <div>
                            {values.timepoints.length} timepoint
                            {values.timepoints.length > 1 ? 's' : ''}
                        </div>
                    </div>
                </div>
                <div className={style.choice}>
                    <div className={style.number}>3</div>
                    <div>
                        <b>Respondents:</b> {actualRespondents.map(item => item.label).join(', ')}
                    </div>
                </div>
                <div className={style.choice}>
                    <div className={style.number}>4</div>
                    <div>
                        <b>Assessment duration:</b>{' '}
                        {actualRespondents
                            .map(item => `${item.label} (${values.durations[item.value]} minutes)`)
                            .join(', ')}
                    </div>
                </div>
            </div>
        </div>
    );
};

Measures.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Measures;
