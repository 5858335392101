import React from 'react';
import { DeploymentCenter } from 'cccisd-laravel-assessment';
import Logo from '../../../images/logo.png';
import style from './style.css';

const AdminDashboard = props => {
    const header = (
        <div className={style.wrapper}>
            <div className={style.panel}>
                <div className={style.panelLeft}>
                    <div className={style.panelHead}>
                        <div className={style.pageTitle}>ADMINISTRATION DASHBOARD</div>
                    </div>
                    <div className={style.panelBody}>
                        <div className={style.pageDescription}>
                            <span className={style.descriptionText}>
                                Select students from the table to start creating and administering Assessment Plans
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.panelRight} />
            </div>
        </div>
    );

    return (
        <DeploymentCenter
            logo={Logo}
            collectionBuckets={['Student', 'Teacher']}
            assessmentLibraryHandle="surveybank"
            contactTypes={['Teacher']}
            assessmentLibraryLabel="Instrument Library"
            header={header}
        />
    );
};

export default AdminDashboard;
