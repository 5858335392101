import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconArrowDown from 'cccisd-icons/arrow-down5';
import IconArrowRight from 'cccisd-icons/arrow-right5';
import IconEdit from 'cccisd-icons/pencil5';
import IconArchive from 'cccisd-icons/box-add';
import IconDelete from 'cccisd-icons/bin';
import Headlight from '../Headlight/Headlight.js';
import style from './style.css';

const renderHeadlight = () => {
    return (
        <Headlight>
            <button type="button" disabled className="btn btn-xs btn-success">
                <IconEdit />
            </button>
            <button type="button" disabled className="btn btn-xs btn-primary">
                <IconArchive />
            </button>
            <button type="button" disabled className="btn btn-xs btn-danger">
                <IconDelete />
            </button>
        </Headlight>
    );
};

const AssessmentCard = ({ description, timepoints, title }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={`${style.cardWrapper} ${isExpanded ? style.expanded : ''}`}>
            {isExpanded && renderHeadlight()}

            <div className={style.header}>
                <div className={style.leftSide} onClick={() => setIsExpanded(prev => !prev)}>
                    <div
                        className={`${style.expanded}`}
                        style={{ marginRight: '1em', position: 'relative', top: '-12px' }}
                    >
                        {isExpanded ? <IconArrowDown /> : <IconArrowRight />}
                    </div>

                    <div className={style.titleArea}>
                        <div className={style.title}>{title}</div>
                        <div className={style.description}>{description}</div>
                    </div>
                </div>
            </div>

            {isExpanded && (
                <>
                    <hr style={{ borderColor: '#BDB8D5' }} />
                    {timepoints.map((tp, i) => (
                        <div className={style.assessmentBar} key={i}>
                            <div className={style.colorBar} />{' '}
                            <div className={style.title}>
                                <strong>{tp.title}</strong>
                            </div>
                            <div className={style.status}>
                                <div>
                                    <strong style={{ marginBottom: '1em' }}>
                                        <span className={tp.status === 'Open' ? style.open : style.closed} />
                                        {tp.status}
                                    </strong>{' '}
                                </div>
                                <div>Open {tp.opens}</div>
                                <div>Close {tp.closes}</div>
                            </div>
                            <div className={style.counts}>
                                <div>{tp.students} student responses</div>
                                <div>{tp.studentRate}% response rate</div>
                                <div className={style.countDivider} />
                                <div>{tp.teachers} teacher responses</div>
                                <div>{tp.teacherRate}% response rate</div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

AssessmentCard.propTypes = {
    description: PropTypes.string,
    timepoints: PropTypes.arrayOf(
        PropTypes.shape({
            closes: PropTypes.string,
            opens: PropTypes.string,
            status: PropTypes.string,
            students: PropTypes.number,
            studentRate: PropTypes.number,
            teachers: PropTypes.number,
            teacherRate: PropTypes.number,
            title: PropTypes.string,
        })
    ),
    title: PropTypes.string,
};

export default AssessmentCard;
