import data, { respondentOptions } from '../data.js';

export default (measures, values) => {
    const result = {};
    const respondents = respondentOptions
        .filter(item => values.respondents[item.value].length > 0)
        .map(item => item.value);

    for (const respondent of respondents) {
        result[respondent] = {};
        const grades = values.respondents[respondent];
        const totalDuration = values.durations[respondent];

        const gradeRelations = data.gradeLevels
            .filter(level => grades.includes(level.code))
            .map((level, index, arr) => {
                const relation = (() => {
                    if (index === 0) {
                        return false;
                    }
                    if (arr[index - 1].band !== level.band) {
                        return false;
                    }

                    return arr.find(item => item.band === level.band).code;
                })();

                return { ...level, relation };
            })
            .filter(level => level.relation)
            .reduce((obj, level) => {
                obj[level.code] = level.relation;
                return obj;
            }, {});

        const filteredMeasures = measures
            .filter(item => {
                return values.assessmentType === 'quick'
                    ? item.respondent === respondent && item.overall
                    : item.respondent === respondent && item.skills.some(skill => values.selAreas.includes(skill));
            })
            .sort((a, b) => a.duration - b.duration);

        const buckets = filteredMeasures.reduce((obj, item) => {
            for (const grade of item.grades) {
                obj[grade] = obj[grade] || [];
                obj[grade].push(item);
            }
            return obj;
        }, {});

        for (const grade of grades) {
            if (gradeRelations[grade]) {
                result[respondent][grade] = gradeRelations[grade];
                continue;
            }

            result[respondent][grade] = [];
            if (!buckets[grade]) {
                continue;
            }

            let duration = 0;
            for (const measure of buckets[grade]) {
                duration += measure.duration;
                result[respondent][grade].push(measure.code);

                if (duration >= totalDuration) {
                    break;
                }
            }
        }
    }

    return result;
};
