import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio.js';
import { respondentOptions } from '../data.js';
import style from './style.css';

const Duration = props => {
    const { values, setFieldValue } = props;
    const { respondents } = values;

    return (
        <div className={style.wrapper}>
            {respondentOptions
                .filter(item => respondents[item.value].length > 0)
                .map(item => (
                    <div key={item.value} className={style.option}>
                        <div />
                        <div>
                            <div className={style.title}>{item.durationTime}</div>
                            <div className={style.timeOptions}>
                                {item.timeOptions.map(time => (
                                    <div key={time.value} className={style.timeOption}>
                                        {time.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={style.label}>{item.durationLabel}</div>
                        <div className={style.timeOptions}>
                            {item.timeOptions.map(time => (
                                <div key={time.value} className={style.timeOption}>
                                    <Radio
                                        onChange={() =>
                                            setFieldValue('durations', {
                                                ...values.durations,
                                                [item.value]: time.value,
                                            })
                                        }
                                        checked={values.durations[item.value] === time.value}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

Duration.propTypes = {
    stageInfo: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Duration;
