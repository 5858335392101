/* eslint-disable global-require */
export default {
    width: 1100,
    height: 720,
    framerate: 2,
    images: [
        require('./ENG_NarratorVideo_0.png'),
        require('./ENG_NarratorVideo_1.png'),
        require('./ENG_NarratorVideo_2.png'),
    ],
    frames: [
        [0, 0, 2048, 1024, 0, 0, 0],
        [0, 1024, 2048, 1024, 0, 0, 0],
        [0, 0, 2048, 1024, 1, 0, 0],
        [0, 1024, 2048, 1024, 1, 0, 0],
        [0, 0, 2048, 1024, 2, 0, 0],
        [0, 1024, 2048, 1024, 2, 0, 0],
    ],
    animations: {
        idleAnimation: { frames: [0], speed: 1 },
        startAnimation: {
            frames: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0,
                0, 0,
            ],
            speed: 1,
        },
    },
};
