import React, { useState } from 'react';
import style from './style.css';
import UtilizationIcon from './images/UtilizationIcon.svg';
import ComparisonIcon from './images/ComparisonIcon.svg';
import PerformanceIcon from './images/PerformanceIcon.svg';
import UtilizationStudentMock from './images/mockups/UtilizationStudentReport.png';
import PerformanceMock from './images/mockups/PerformanceReport.png';
import ComparisonSchoolMock1 from './images/mockups/SchoolComparisonReport1.png';
import ComparisonSchoolMock2 from './images/mockups/SchoolComparisonReport2.png';
import ComparisonGradeMock1 from './images/mockups/GradeComparisonReport1.png';
import ComparisonGradeMock2 from './images/mockups/GradeComparisonReport2.png';
import Equalizer from 'cccisd-icons/equalizer';
import ArrowLeft from 'cccisd-icons/arrow-left8';
import IconInfo from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import Heading from '../../components/Heading';

const DistrictField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>District:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>All</option>
                </select>
            </div>
        </div>
    );
};

const SchoolField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>School:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>School A</option>
                </select>
            </div>
        </div>
    );
};

const YearField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>School Year:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>2021-2022</option>
                </select>
            </div>
        </div>
    );
};

const ClassroomField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>Classroom:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>Mr. Adrian Adams</option>
                </select>
            </div>
        </div>
    );
};

const StudentField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>Students:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>Layla Hodge</option>
                </select>
            </div>
        </div>
    );
};

const DomainsField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>Domains:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>Overall Social Competence</option>
                </select>
            </div>
        </div>
    );
};
const GradeField = () => {
    return (
        <div className={style.formField}>
            <div className={style.fieldLabel}>Grades:</div>
            <div>
                <select disabled className={`${style.selectField} form-control`}>
                    <option value={0}>Third, seventh, tenth</option>
                </select>
            </div>
        </div>
    );
};
const DomainsFilter = () => {
    return (
        <div className={style.twoPill}>
            <div>
                <strong>Domains</strong>
            </div>
            <div>Measures</div>
        </div>
    );
};
const StudentsFilter = () => {
    return (
        <div className={style.threePill}>
            <div>Schools</div>
            <div>Classrooms</div>
            <div>
                <strong>Students</strong>
            </div>
        </div>
    );
};
const CheckboxFilters = ({ first, second }) => {
    return (
        <div className={style.checkboxFilters}>
            <div>
                <input type="checkbox" disabled checked={first} /> Show raw score{' '}
                <Tooltip title="In SEL Quest, raw scores are converted to a 1 to 10 scale to allow for comparison across measures. Raw scores are values associated with the original scale of each measure which may differ from the rescaled 1 to 10 format used in SEL Quest. Raw scores are shown in parentheses nest to the scaled scores.">
                    <IconInfo />
                </Tooltip>
            </div>
            <div>
                <input type="checkbox" disabled checked={second} /> Compare to district average{' '}
                <Tooltip title="The district average displays the average score for all students' results for the selected grade level (e.g. grade 6) within the selected district.">
                    <IconInfo />
                </Tooltip>
            </div>
        </div>
    );
};
const reports = [
    {
        handle: 'performance',
        title: 'SEL Performance Report',
        level: {
            title: 'Report Level',
            text: () => {
                return <p>Select the level of report you wish to view.</p>;
            },
            component: () => {
                return <StudentsFilter />;
            },
        },
        filters: () => {
            return (
                <>
                    <DistrictField /> <SchoolField /> <ClassroomField /> <StudentField /> <YearField />
                </>
            );
        },
        checkboxFilters: () => {
            return <CheckboxFilters first second />;
        },
        mockups: [PerformanceMock],
    },
    {
        handle: 'schoolComparison',
        title: 'School Comparison Report',
        level: {
            title: 'Report Level',
            text: () => {
                return (
                    <p>
                        Select the level of report you wish to view. To see a school comparison broken out by each SEL
                        area, select <strong>Domains</strong>. To see breakdown by individual measures, select{' '}
                        <strong>Measures</strong>.
                    </p>
                );
            },
            component: () => {
                return <DomainsFilter />;
            },
        },
        filters: () => {
            return (
                <>
                    <DistrictField /> <SchoolField /> <DomainsField /> <YearField />
                </>
            );
        },
        checkboxFilters: () => {
            return <CheckboxFilters second />;
        },
        mockups: [ComparisonSchoolMock1, ComparisonSchoolMock2],
    },
    {
        handle: 'gradeComparison',
        title: 'Grade Comparison Report',
        level: {
            title: 'Report Level',
            text: () => {
                return (
                    <p>
                        Select the level of report you wish to view. To see a school comparison broken out by each SEL
                        area, select <strong>Domains</strong>. To see breakdown by individual measures, select{' '}
                        <strong>Measures</strong>.
                    </p>
                );
            },
            component: () => {
                return <DomainsFilter />;
            },
        },
        filters: () => {
            return (
                <>
                    <DistrictField /> <GradeField /> <DomainsField /> <YearField />
                </>
            );
        },
        checkboxFilters: () => {
            return <CheckboxFilters first second />;
        },
        mockups: [ComparisonGradeMock1, ComparisonGradeMock2],
    },

    {
        handle: 'utilization',
        title: 'Utilization Report',
        level: {
            title: 'Report Level',
            text: () => {
                return <p>Select the level of report you wish to view.</p>;
            },
            component: () => {
                return <StudentsFilter />;
            },
        },
        checkboxFilters: () => {
            return null;
        },
        filters: () => {
            return (
                <>
                    <DistrictField /> <SchoolField /> <ClassroomField /> <StudentField /> <YearField />
                </>
            );
        },
        mockups: [UtilizationStudentMock],
    },
];

const reportCards = [
    {
        handle: 'performance',
        title: 'SEL Performance Report',
        text: 'These reports display SEL performance results for each SEL area assessed using SEL Quest. Use the filters to view results for specific schools, grades, classrooms, and students. Results can also be compared to your district average.',
        icon: PerformanceIcon,
    },
    {
        handle: 'schoolComparison',
        title: 'School Comparison Report',
        text: 'These reports display SEL performance results side by side for comparison of two or more schools for each SEL area assessed. Results can also be compared to your district average',
        icon: ComparisonIcon,
    },
    {
        handle: 'gradeComparison',
        title: 'Grade Comparison Report',
        text: 'These reports display SEL performance results side by side for comparison of two or more grades or grade-levels for each SEL area assessed. Results can also be compared to your district',
        icon: ComparisonIcon,
    },
    {
        handle: 'utilization',
        title: 'Utilization Report',
        text: 'These reports provide a historical record of all assessments completed using SEL Quest. Use filters to organize results by school, grade, classroom, or student.',
        icon: UtilizationIcon,
    },
];

const Reports = () => {
    const [reportView, setReportView] = useState('cards');

    const switchView = nextView => {
        setReportView(nextView);
    };

    const renderCardView = () => {
        return (
            <>
                <div>
                    <Heading text="Reports" />
                    <p>
                        Generate reports of SEL performance and SEL assessment utilization by school, grade, classroom
                        or student.
                    </p>
                </div>
                <div className="row">
                    {reportCards.map((card, i) => {
                        return (
                            <div className="col-xs-12 col-sm-6 col-lg-4" key={i}>
                                <div className={style.card}>
                                    <div className={style.swathe}>
                                        <div className={style.titleBox}>
                                            <img src={card.icon} alt="Icon" /> <h4>{card.title}</h4>
                                        </div>
                                    </div>
                                    <div className={style.cardBody}>{card.text}</div>
                                    <div className={style.buttonBox}>
                                        <button
                                            className={`btn ${style.reportButton}`}
                                            type="button"
                                            onClick={() => {
                                                switchView(card.handle);
                                            }}
                                        >
                                            Go to Reports
                                        </button>
                                        <button className={`btn ${style.sampleButton}`} type="button">
                                            View Sample
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    if (reportView === 'cards') {
        return <>{renderCardView()}</>;
    }

    const currentView = reportView !== 'cards' && reports.find(item => item.handle === reportView);
    const currentCard =
        reportView !== 'cards' && currentView && reportCards.find(item => item.handle === currentView.handle);

    return (
        <div className="container" style={{ marginTop: '20px' }}>
            {reportView !== 'cards' && (
                <>
                    <div
                        style={{ textAlign: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            switchView('cards');
                        }}
                    >
                        <ArrowLeft spaceRight /> Go Back{' '}
                    </div>
                    <h3>{currentCard.title}</h3>
                    <p>{currentCard.text}</p>
                    <div className={style.levelBox}>
                        <h4>
                            {currentView.level.title} <Equalizer spaceLeft />
                        </h4>
                        <p>{currentView.level.text()}</p>
                        {currentView.level.component()}
                    </div>
                    <div className={style.filterBox}>
                        <div className={style.filterBoxTitle}>
                            <h4>
                                {currentView.title} Filters <Equalizer spaceLeft />
                            </h4>

                            <p>
                                Click on the drop downs below to filter user data, then click <span>Apply Filters</span>
                            </p>
                        </div>
                        <div className={style.filterBar}>{currentView.filters()}</div>
                        {currentView.checkboxFilters()}
                        <div className={style.buttonRow}>
                            <div>
                                Number of Respondents:{' '}
                                {currentView.handle === 'performance' || currentView.handle === 'utilization'
                                    ? '001'
                                    : '800'}
                            </div>
                            <button type="button" disabled className={`btn ${style.applyButton}`}>
                                Print Report
                            </button>
                        </div>
                    </div>
                    <div className={style.reportBox}>
                        <div className={style.banner}>
                            <h4>{currentView.title}</h4>
                        </div>
                        <div className={style.reportSection}>
                            {currentView.mockups.map((mock, i) => (
                                <div className={style.page}>
                                    <img key={i} src={mock} alt={currentView.handle} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Reports;
