import React from 'react';
import PropTypes from 'prop-types';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconCross from 'cccisd-icons/cancel-circle2';
import quickIcon from './quick.png';
import detailedIcon from './detailed.png';
import classnames from 'classnames';
import style from './style.css';

export const assessmentTypeOptions = [
    {
        value: 'quick',
        label: 'Quick overview',
        description: 'Produces an overall competence score for each student.',
        icon: quickIcon,
        useCases: 'Brief screener; Overview of current functioning; Less informative of specific strengths vs. needs.',
        pros: ['Brief', 'Help identify students who need more thorough assessment'],
        cons: ['No breakdown by SEL domain or skill', 'Less informative of particular strengths vs needs'],
    },
    {
        value: 'detailed',
        label: 'Detailed overview',
        description: 'Produces scores for one or more SEL domains.',
        icon: detailedIcon,
        useCases:
            'Identify specific strength and needs; Inform SEL program selection and effectiveness; Guide instructional decision-making',
        pros: [
            'Scores for specific SEL domain(s)',
            'More information to determine if SEL program was effective.',
            'More information to decide what to teach to whom.',
        ],
        cons: [
            'Can be more lengthy (if multiple domains are assessed)',
            'Detailed information requires more interpretation of results',
            'Requires more detailed understanding of the range of available SEL program offerings and ways to integrate into curriculum.',
        ],
    },
];

const AssessmentType = props => {
    const { values, setFieldValue } = props;

    return (
        <div className={style.wrapper}>
            {assessmentTypeOptions.map(item => (
                <div
                    key={item.value}
                    className={classnames(style.block, {
                        [style.selected]: item.value === values.assessmentType,
                    })}
                    onClick={() => setFieldValue('assessmentType', item.value)}
                >
                    <div className={style.header}>
                        <div className={style.radio} />
                        <div className={style.icon} style={{ backgroundImage: `url("${item.icon}")` }} />
                        <div className={style.title}>
                            <b>{item.label}</b> of SEL competence
                        </div>
                        <div className={style.description}>{item.description}</div>
                        <div className={style.useCases}>
                            <b>Use cases:</b> {item.useCases}
                        </div>
                    </div>
                    <div className={style.prosCons}>
                        <div className={style.pros + ' alert alert-success'}>
                            <div className={style.title}>Pros</div>
                            <div className={style.list}>
                                {item.pros.map((str, index) => (
                                    <div key={index}>
                                        <IconCheck />
                                        {str}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={style.cons + ' alert alert-danger'}>
                            <div className={style.title}>Cons</div>
                            <div className={style.list}>
                                {item.cons.map((str, index) => (
                                    <div key={index}>
                                        <IconCross />
                                        {str}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

AssessmentType.propTypes = {
    stageInfo: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default AssessmentType;
