import React, { useState, useEffect } from 'react';
import style from './style.css';
import { client } from 'cccisd-apollo';
import IconBrush from 'cccisd-icons/brush';
import Loader from 'cccisd-loader';
import Heading from '../../../components/Heading';
import AssessmentCard from './AssessmentCard';
import assessmentsQuery from './assessments.graphql';

const assessmentsContent = [
    {
        assignmentPlanId: 1,
        defaultTitle: 'District-wide Early Elementary',
        description: 'Self Management Skills, Relationship Skills',
        timepoints: [
            {
                opens: 'January 01, 2023',
                closes: 'August 01, 2023',
                status: 'Open',
                students: 226,
                studentRate: 100,
                teachers: 12,
                teacherRate: 92,
                title: 'Spring 2023',
            },
            {
                opens: 'August 01, 2023',
                closes: 'January 01, 2024',
                status: 'Closed',
                students: 226,
                studentRate: 100,
                teachers: 11,
                teacherRate: 100,
                title: 'Fall 2023',
            },
        ],
    },
    {
        assignmentPlanId: 3,
        defaultTitle: 'District-wide Intermediate School',
        description: 'Relationship Skills, Responsible Decision Making',
        timepoints: [
            {
                opens: 'January 01, 2023',
                closes: 'August 01, 2023',
                status: 'Open',
                students: 151,
                studentRate: 100,
                teachers: 0,
                teacherRate: 0,
                title: 'Spring 2023',
            },
            {
                opens: 'August 01, 2023',
                closes: 'January 01, 2024',
                status: 'Closed',
                students: 151,
                studentRate: 100,
                teachers: 0,
                teacherRate: 0,
                title: 'Fall 2023',
            },
        ],
    },
    {
        assignmentPlanId: 2,
        defaultTitle: "Fall '23 Late Elementary",
        description: 'Social Awareness',
        timepoints: [
            {
                opens: 'August 01, 2023',
                closes: 'Januray 01, 2024',
                status: 'Closed',
                students: 148,
                studentRate: 100,
                teachers: 7,
                teacherRate: 100,
                title: 'Fall 2023',
            },
        ],
    },
    {
        assignmentPlanId: 4,
        defaultTitle: "Fall '23 Secondary School",
        description: 'Social Awareness, Self-Awareness',
        timepoints: [
            {
                opens: 'August 01, 2023',
                closes: 'Januray 01, 2024',
                status: 'Closed',
                students: 475,
                studentRate: 81,
                teachers: 0,
                teacherRate: 0,
                title: 'Fall 2023',
            },
        ],
    },
];

const LandingPage = props => {
    const [assessments, setAssessments] = useState(null);

    useEffect(() => {
        async function getAssessments() {
            const resp = await client.query({ query: assessmentsQuery, fetchPolicy: 'network-only' });
            const assignmentPlans = resp?.data?.flows?.assignmentPlanList || [];
            setAssessments(
                assessmentsContent.map(assessment => {
                    const queryResult = assignmentPlans.find(ap => ap.assignmentPlanId === assessment.assignmentPlanId);
                    const title = queryResult?.label || assessment.defaultTitle;
                    return { ...assessment, title };
                })
            );
        }

        getAssessments();
    }, []);

    if (!assessments) {
        return <Loader loading />;
    }

    return (
        <>
            <div className={style.heading}>
                <Heading text="Assessments" />
                <button type="button" className="btn btn-primary" onClick={props.buildNew}>
                    <IconBrush spaceRight /> Build New Assessment
                </button>
            </div>

            {assessments.map((assessment, i) => (
                <AssessmentCard {...assessment} key={i} />
            ))}
        </>
    );
};

export default LandingPage;
