/* eslint-disable global-require */
export default {
    framerate: 1,
    width: 400,
    height: 400,
    images: [require('./ENG_SPS6-HitByBall.png')],
    frames: [
        [0, 0, 512, 512, 0, 0, 0],
        [512, 0, 512, 512, 0, 0, 0],
        [1024, 0, 512, 512, 0, 0, 0],
    ],
    animations: {
        startAnimation: {
            frames: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2],
            speed: 1,
        },
        idleAnimation: { frames: [2], speed: 1 },
    },
};
