import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import CccisdToggle from 'cccisd-toggle';
import _xor from 'lodash/xor';
import data from '../../data.js';
import style from './style.css';

const allMeasures = data.measures;

const skillMap = data.skills.reduce((obj, skill) => {
    obj[skill.code] = skill.label;
    return obj;
}, {});

const AddNewMeasure = props => {
    const { closeModal, setFieldValue, values, respondent, grade, typeMap, languageMap, respondentMap } = props;
    const [selectedMeasures, setSelectedMeasures] = useState(values.measures?.[respondent]?.[grade] || []);

    const typeOptions = Object.entries(typeMap).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const availableMeasures = useMemo(() => {
        return allMeasures.filter(item => item.respondent === respondent);
    }, [respondent]);

    const totalDuration = availableMeasures.reduce((sum, measure) => {
        if (selectedMeasures.includes(measure.code)) {
            sum += measure.duration;
        }

        return sum;
    }, 0);

    const updateMeasures = () => {
        setFieldValue('measures', {
            ...values.measures,
            [respondent]: {
                ...values.measures[respondent],
                [grade]: selectedMeasures,
            },
        });

        closeModal();
    };

    const columns = [
        {
            name: 'selected',
            label: 'Select',
            render: (value, row) => (
                <CccisdToggle
                    value={selectedMeasures.includes(row.code)}
                    onChange={() => setSelectedMeasures(_xor(selectedMeasures, [row.code]))}
                />
            ),
        },
        {
            name: 'label',
            label: 'Title',
            render: (value, row) => {
                return row.pdf ? (
                    <a href={row.pdf} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                ) : (
                    value
                );
            },
            sort: true,
            filter: true,
        },
        {
            name: 'area',
            label: (
                <>
                    Area
                    <br />
                    (Sub-area)
                </>
            ),
            render: value => value.map(item => skillMap[item]).join(', '),
            sort: true,
            filter: true,
        },
        {
            name: 'type',
            label: 'Type',
            render: value => typeMap[value],
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: typeOptions,
            },
        },
        {
            name: 'respondent',
            label: 'Respondent',
            render: value => respondentMap[value],
            sort: true,
            filter: true,
        },
        {
            name: 'language',
            label: 'Language',
            render: value => value.map(item => languageMap[item]).join(', '),
            sort: true,
            filter: true,
        },
        { name: 'duration', label: 'Duration' },
    ];

    return (
        <div>
            <Table
                rowKey="code"
                columns={columns}
                data={availableMeasures}
                showPerPageOptions={false}
                perPage={200}
                hideShowingRowsSummary
                saveState={false}
            />
            <div className={style.duration}>Total: {totalDuration} minutes</div>
            <div style={{ marginTop: '1em' }}>
                <button type="button" className="btn btn-primary" onClick={updateMeasures}>
                    Update measures
                </button>
            </div>
        </div>
    );
};

AddNewMeasure.propTypes = {
    closeModal: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    respondent: PropTypes.string,
    grade: PropTypes.string,
    typeMap: PropTypes.object,
    languageMap: PropTypes.object,
    respondentMap: PropTypes.object,
};

export default AddNewMeasure;
