import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { Field, FieldArray, CccisdDatepicker } from 'cccisd-formik';
import IconCalendar from 'cccisd-icons/calendar';
import IconInfo from 'cccisd-icons/info2';

const timesOptions = [
    { value: '1', label: 'One time' },
    { value: '2', label: 'Two times' },
    { value: '3', label: 'Three times' },
    { value: '4', label: 'Four times' },
];

const Schedule = props => {
    const { values, setFieldValue } = props;

    useEffect(() => {
        const needTimepointsGeneration = values.timepoints.every(item => !item.openDate && !item.closeDate);
        if (!needTimepointsGeneration) {
            return;
        }

        setFieldValue(
            'timepoints',
            new Array(values.purpose === 'snapshot' ? 1 : 2)
                .fill(0)
                .map((_, index) => ({ title: `Time ${index + 1}`, openDate: '', closeDate: '' }))
        );
    }, []);

    const size = values.timepoints.length;

    return (
        <>
            <div className={style.wrapper}>
                <div className={style.header}>How many times would you like to conduct the assessment?</div>

                <FieldArray name="timepoints">
                    {({ push, pop }) => (
                        <div className={style.content}>
                            <div style={{ marginBottom: '1em' }}>
                                <select
                                    className="form-control"
                                    style={{ width: 'auto' }}
                                    value={size.toString()}
                                    onChange={e => {
                                        const newValue = +e.target.value;

                                        for (let i = size + 1; i <= newValue; i++) {
                                            push({
                                                title: `Time ${i}`,
                                                openDate: '',
                                                closeDate: '',
                                            });
                                        }
                                        for (let i = size - 1; i >= newValue; i--) {
                                            pop();
                                        }
                                    }}
                                >
                                    {timesOptions.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={style.timepoints}>
                                {values.timepoints.map((timepoint, index) => (
                                    <div className={style.timepoint} key={index}>
                                        <div className={style.title}>
                                            <IconCalendar spaceRight />
                                            Time {index + 1}
                                        </div>
                                        <div className={style.dates}>
                                            <div>
                                                <div>Open date:</div>
                                                <div className={style.datepickerWrapper}>
                                                    <Field
                                                        name={`timepoints.${index}.openDate`}
                                                        component={CccisdDatepicker}
                                                        label={null}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div>Close date:</div>
                                                <div className={style.datepickerWrapper}>
                                                    <Field
                                                        name={`timepoints.${index}.closeDate`}
                                                        component={CccisdDatepicker}
                                                        label={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </FieldArray>
            </div>
            <div className="alert alert-warning" style={{ margin: '1em 0 0' }}>
                <IconInfo spaceRight />
                You can always add more timepoints later after the assessment is created
            </div>
        </>
    );
};

Schedule.propTypes = {
    values: PropTypes.object,
};

export default Schedule;
