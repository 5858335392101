import React from 'react';
import { DataBrowser } from 'cccisd-laravel-assignment';
import Heading from '../../components/Heading';
import { isMetuchenAdmin } from '../../helpers';

const Fortress = window.cccisd.fortress;

const Data = props => {
    const isOrgAdmin = Fortress.user.acting.role.handle === 'orgAdmin';
    if (isOrgAdmin && !isMetuchenAdmin()) {
        return null;
    }

    // Assume all orgAdmin below are metuchen admin
    let dataBrowserProps = { showVisualizeButton: true, showCustomizeButton: false };
    if (isOrgAdmin) {
        dataBrowserProps.projectId = 91;
        dataBrowserProps.showQueriesWithTags = ['msdAdmin'];
    } else {
        dataBrowserProps.projectId = 1;
        dataBrowserProps.showQueriesWithTags = ['orgAdmin'];
    }

    return (
        <div style={{ marginTop: '20px' }}>
            <Heading text="Data" />
            <DataBrowser {...dataBrowserProps} />
        </div>
    );
};

export default Data;
