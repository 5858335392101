/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import data from '../data.js';
import IconQuestion from 'cccisd-icons/question4';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import IconChecked from 'cccisd-icons/svg/checkbox-checked2';
import IconUnchecked from 'cccisd-icons/svg/checkbox-unchecked2';
import IconPartial from 'cccisd-icons/svg/checkbox-partial2';
import Tooltip from 'cccisd-tooltip';
import _xor from 'lodash/xor';
import _difference from 'lodash/difference';
import _union from 'lodash/union';
import _mapValues from 'lodash/mapValues';
import { animated } from '@react-spring/web';
import { ResponsivePie } from 'cccisd-nivo/pie';
import Modal from 'cccisd-modal';
import style from './style.css';

const chartData = [
    {
        id: 2,
        label: 'Self-\nManagement',
        title: 'Self-Management',
        value: 100,
        description:
            'Managing one’s emotions, thoughts, and behaviors effectively in different situations and to achieving goals and aspirations.',
        vimeo: 'https://player.vimeo.com/video/743531624?h=9439c71311',
        pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/f6/f6c9c1701591ef81891e4c40a94cb912.pdf',
        color: '#8e87ad',
    },
    {
        id: 3,
        label: 'Responsible\nDecision-\nMaking',
        title: 'Responsible Decision-Making',
        value: 100,
        description:
            'Making caring and constructive choices about personal behavior and social interactions across diverse situations.',
        vimeo: 'https://player.vimeo.com/video/743531462?h=8599f017c1',
        pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/24/24f0cfd8e2e195f668bc2e12f8c4d7ca.pdf',
        color: '#97ccef',
    },
    {
        id: 4,
        label: 'Relationship\nSkills',
        title: 'Relationship Skills',
        value: 100,
        description:
            'Establishing and maintaining healthy and supportive relationships, and effectively navigating settings with diverse individuals and groups.',
        vimeo: 'https://player.vimeo.com/video/743531310?h=17fd5da109',
        pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/fb/fb58a360c15bba06a2f4cc455929e2b5.pdf',
        color: '#569acd',
    },
    {
        id: 5,
        label: 'Social\nAwareness',
        title: 'Social Awareness',
        value: 100,
        description:
            'Understanding the perspectives of, and empathize with, others, including those from diverse backgrounds, cultures, and contexts.',
        vimeo: 'https://player.vimeo.com/video/743531683?h=88a76e5b0a',
        pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/06/0679a6dc7644689084f8fce93f9b73c6.pdf',
        color: '#569acd',
    },
    {
        id: 1,
        label: 'Self-\nAwareness',
        title: 'Self-Awareness',
        value: 100,
        description:
            'Understanding one’s own emotions, thoughts, and values and how they influence behavior across contexts.',
        vimeo: 'https://player.vimeo.com/video/743531522?h=7bd5842ee4',
        pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/fb/fbb5bf623ac4c2060d382159dee2b553.pdf',
        color: '#8e87ad',
    },
];

const SkillsSelector = props => {
    const { values, setFieldValue } = props;
    const [expanded, setExpanded] = useState([]);
    const [selectedAreaId, setSelectedAreaId] = useState(1);
    const modalRef = useRef();

    const groupSelected = _mapValues(
        data.skills.reduce((obj, group) => {
            if (!(group.code in obj)) {
                obj[group.code] = { count: 0, length: group.list.length };
            }

            for (const skill of group.list) {
                if (values.selAreas.includes(skill.code)) {
                    obj[group.code].count++;
                }
            }

            return obj;
        }, {}),
        obj => {
            if (obj.count === obj.length) {
                return 'all';
            }
            if (obj.count === 0) {
                return 'none';
            }
            return 'partial';
        }
    );

    const expandGroup = code => {
        setExpanded(_xor(expanded, [code]));
    };

    const toggleGroup = code => {
        const groupSkills = data.skills.find(group => group.code === code).list.map(skill => skill.code);

        if (groupSelected[code] === 'all') {
            setFieldValue('selAreas', _difference(values.selAreas, groupSkills));
        } else {
            setFieldValue('selAreas', _union(values.selAreas, groupSkills));
        }
    };
    const toggleSkill = code => {
        setFieldValue('selAreas', _xor(values.selAreas, [code]));
    };

    const selectedArea = chartData.find(item => item.id === selectedAreaId);

    return (
        <div>
            <div className={style.wrapper}>
                <div className={style.groupWrapper}>
                    {data.skills.map(group => {
                        const isExpanded = expanded.includes(group.code);

                        return (
                            <div key={group.code}>
                                <div className={style.group}>
                                    <div className={style.header}>
                                        <div className={style.expand} onClick={() => expandGroup(group.code)}>
                                            {isExpanded ? <IconArrowDown /> : <IconArrowRight />}
                                        </div>
                                        <span className={style.check} onClick={() => toggleGroup(group.code)}>
                                            {(() => {
                                                if (groupSelected[group.code] === 'all') {
                                                    return <IconChecked />;
                                                }
                                                if (groupSelected[group.code] === 'partial') {
                                                    return <IconPartial />;
                                                }
                                                return <IconUnchecked />;
                                            })()}
                                        </span>
                                        <div className={style.title}>{group.label}</div>
                                    </div>
                                    {isExpanded && (
                                        <div className={style.list}>
                                            {group.list.map(skill => (
                                                <div key={skill.code}>
                                                    <label style={{ margin: 0 }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={values.selAreas.includes(skill.code)}
                                                            onChange={() => toggleSkill(skill.code)}
                                                            style={{ marginRight: '0.5em' }}
                                                        />
                                                        <span style={{ fontWeight: 'normal' }}>{skill.label}</span>
                                                    </label>
                                                    {skill.description && (
                                                        <Tooltip title={skill.description}>
                                                            <IconQuestion spaceLeft />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={style.learnWrapper}>
                    <div className={style.learnTitle}>Not sure? Select an area to learn more.</div>
                    <div style={{ width: '100%', height: '400px' }}>
                        <ResponsivePie
                            data={chartData}
                            innerRadius={0.4}
                            isInteractive
                            activeOuterRadiusOffset={7}
                            onClick={datum => {
                                setSelectedAreaId(datum.id);
                                modalRef.current.open();
                            }}
                            arcLabelsComponent={({ datum, label, style: style1 }) => {
                                const words = datum.label.split('\n');

                                return (
                                    <animated.g transform={style1.transform} style={{ pointerEvents: 'none' }}>
                                        <text textAnchor="middle" dominantBaseline="central" style={{ fontSize: 15 }}>
                                            {words.length === 1 ? (
                                                words[0]
                                            ) : words.length === 2 ? (
                                                <>
                                                    <tspan y="-11">{words[0]}</tspan>
                                                    <tspan x="0" dy="22">
                                                        {words[1]}
                                                    </tspan>
                                                </>
                                            ) : (
                                                <>
                                                    <tspan y="-22">{words[0]}</tspan>
                                                    <tspan x="0" dy="22">
                                                        {words[1]}
                                                    </tspan>
                                                    <tspan x="0" dy="22">
                                                        {words[2]}
                                                    </tspan>
                                                </>
                                            )}
                                        </text>
                                    </animated.g>
                                );
                            }}
                            colors={chartData.map(item => item.color)}
                            legends={[]}
                            tooltip={() => null}
                            enableArcLinkLabels={false}
                            margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                            onMouseEnter={(_, event) => {
                                event.currentTarget.style.cursor = 'pointer';
                            }}
                            onMouseLeave={(_, event) => {
                                event.currentTarget.style.cursor = 'auto';
                            }}
                            layers={[
                                'arcs',
                                'arcLabels',
                                ({ dataWithArc, centerX, centerY }) => (
                                    <text
                                        x={centerX}
                                        y={centerY}
                                        textAnchor="middle"
                                        dominantBaseline="central"
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        <tspan x={centerX} y={centerY - 33}>
                                            Social
                                        </tspan>
                                        <tspan x={centerX} dy="22">
                                            and
                                        </tspan>
                                        <tspan x={centerX} dy="22">
                                            Emotional
                                        </tspan>
                                        <tspan x={centerX} dy="22">
                                            Learning
                                        </tspan>
                                    </text>
                                ),
                            ]}
                        />
                    </div>
                </div>
            </div>
            <Modal title={selectedArea.title} trigger={null} ref={modalRef}>
                <div>
                    <iframe
                        title={selectedArea.title}
                        src={selectedArea.vimeo}
                        width="568"
                        height="320"
                        frameBorder="0"
                        allowFullScreen
                    />
                    <h3>{selectedArea.title}</h3>
                    <div style={{ marginBottom: '1em' }}>{selectedArea.description}</div>
                    {selectedArea.pdf && (
                        <div style={{ textAlign: 'right' }}>
                            <a href={selectedArea.pdf} target="_blank" rel="noopener noreferrer">
                                Learn More
                            </a>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

SkillsSelector.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default SkillsSelector;
