import React from 'react';
import Table from 'cccisd-graphql-table';
import style from './style.css';

const ProgressTable = props => {
    const { tableProps, assignmentPlanId, timepoint, noTimepoints, deploymentId } = props;

    const UpperCase = ({ value }) => {
        if (typeof value !== 'string') {
            return <span>{value}</span>;
        }
        return <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>;
    };

    const Grade = ({ value }) => {
        return <span>{value === '0' ? 'K' : value}</span>;
    };

    const PercentCompleted = ({ row }) => {
        const totalLearners = row['descendantRoles.total'];

        const completedCount = row['descendantRoles.completed'];

        let percent = Math.round((completedCount / totalLearners) * 100);
        if (totalLearners === 0) {
            percent = 0;
        }

        return <span>{percent}%</span>;
    };

    const Status = ({ row }) => {
        const path = `assignmentProgressStatus.assignment${assignmentPlanId}`;
        const statCode = row[path];
        let status;

        switch (statCode) {
            case '0':
                status = 'Not Started';
                break;
            case '1':
                status = 'In Progress';
                break;
            case '2':
                status = 'Completed';
                break;
            default:
                status = '';
        }

        return status;
    };

    const StartedCount = ({ row }) => {
        const studentList = row['descendantRoles.learnerList'];
        const key = `assignment${assignmentPlanId}`;

        let startedCount = 0;

        studentList.forEach(item => {
            const statCode = item.assignmentProgressStatus[key];

            if (statCode === '1' || statCode === '2') {
                startedCount++;
            }
        });

        return startedCount;
    };

    const CompletedCount = ({ row }) => {
        const studentList = row['descendantRoles.learnerList'];
        const key = `assignment${assignmentPlanId}`;

        let completedCount = 0;

        studentList.forEach(item => {
            const statCode = item.assignmentProgressStatus[key];

            if (statCode === '2') {
                completedCount++;
            }
        });

        return completedCount;
    };

    const renderTableView = () => {
        const graphqlVariables = noTimepoints
            ? { assignmentPlanId }
            : {
                  assignmentPlanId,
                  deploymentId,
              };

        return (
            <div className={style.topSpace}>
                <Table
                    key={timepoint}
                    {...tableProps}
                    graphqlVariables={graphqlVariables}
                    noRecordsMessage="No records to display."
                    renders={{
                        PercentCompleted,
                        UpperCase,
                        Grade,
                        Status,
                        StartedCount,
                        CompletedCount,
                    }}
                />
            </div>
        );
    };

    return <div>{renderTableView()}</div>;
};

export default ProgressTable;
