/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import data, { respondentOptions } from '../data.js';
import IconClock from 'cccisd-icons/clock';
import IconEarth from 'cccisd-icons/earth';
import IconUser from 'cccisd-icons/users4';
import IconClipboard from 'cccisd-icons/clipboard2';
import IconCross from 'cccisd-icons/svg/cross4';
import IconArrowResize from 'cccisd-icons/svg/arrow-resize4';
import IconCheck from 'cccisd-icons/checkmark';
import Modal from 'cccisd-modal';
import AddNewMeasure from './AddNewMeasure';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import IconInfo from 'cccisd-icons/info2';
import AssesmentChoices from './AssessmentChoices';
import pickMeasures from './pickMeasures.js';
import style from './style.css';

export const typeMap = {
    direct: 'Direct Assessment',
    selfReport: 'Self Report',
    survey: 'Survey',
};
export const languageMap = {
    en: 'English',
    es: 'Spanish',
};

export const respondentMap = respondentOptions.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
}, {});

const gradeMap = data.gradeLevels.reduce((obj, item) => {
    obj[item.code] = item.label;
    return obj;
}, {});

const skillMap = data.skills.reduce((obj, item) => {
    obj[item.code] = item;
    return obj;
}, {});

const measureMap = data.measures.reduce((obj, item) => {
    obj[item.code] = item;
    return obj;
}, {});

const reorderArray = (arr, from, to) => {
    const movedItem = arr[from];
    const remainingItems = arr.filter((item, index) => index !== from);

    return [...remainingItems.slice(0, to), movedItem, ...remainingItems.slice(to)];
};

const Measures = props => {
    const { values, setFieldValue } = props;

    useEffect(() => {
        if (!values.needMeasuresGeneration) {
            return;
        }

        const recommendedMeasures = pickMeasures(data.measures, values);
        setFieldValue('measures', recommendedMeasures);
        setFieldValue('needMeasuresGeneration', false);
    }, []);

    const sameGrades = useMemo(() => {
        const result = {};

        const respondents = respondentOptions
            .filter(item => values.respondents[item.value].length > 0)
            .map(item => item.value);

        for (const respondent of respondents) {
            result[respondent] = {};
            const grades = values.respondents[respondent];

            const gradeRelations = data.gradeLevels
                .filter(level => grades.includes(level.code))
                .map((level, index, arr) => {
                    const relation = (() => {
                        if (index === 0) {
                            return false;
                        }
                        if (arr[index - 1].band !== level.band) {
                            return false;
                        }

                        return arr.find(item => item.band === level.band).code;
                    })();

                    return { ...level, relation };
                })
                .filter(level => level.relation)
                .reduce((obj, level) => {
                    obj[level.code] = level.relation;
                    return obj;
                }, {});

            result[respondent] = gradeRelations;
        }

        return result;
    }, [values.respondents]);

    const removeFromLevel = (respondent, grade, measureCode) => {
        const newMeasures = (values.measures?.[respondent]?.[grade] || []).filter(code => code !== measureCode);

        setFieldValue('measures', {
            ...values.measures,
            [respondent]: {
                ...values.measures[respondent],
                [grade]: newMeasures,
            },
        });
    };

    const onDragEnd = result => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const respondent = result.type.split('_')[0];
        const grade = result.source.droppableId;
        const newMeasures = reorderArray(
            values.measures[respondent][grade],
            result.source.index,
            result.destination.index
        );
        setFieldValue('measures', {
            ...values.measures,
            [respondent]: {
                ...values.measures[respondent],
                [grade]: newMeasures,
            },
        });
    };

    const copyMeasures = (respondent, grade) => {
        const copiedGrade = values.measures[respondent][grade];
        setFieldValue('measures', {
            ...values.measures,
            [respondent]: {
                ...values.measures[respondent],
                [grade]: [...values.measures[respondent][copiedGrade]],
            },
        });
    };

    const renderGroupMeasures = (respondent, grade) => {
        if (!values.measures[respondent]) {
            return null;
        }

        const measures = values.measures[respondent][grade.code] || [];
        const isSame = typeof measures === 'string';
        const sameAs = sameGrades[respondent]?.[grade.code];
        const duration = isSame
            ? 0
            : measures.reduce((total, measureCode) => total + measureMap[measureCode].duration, 0);

        return (
            <DragDropContext onDragEnd={onDragEnd} key={grade.code}>
                <Droppable droppableId={grade.code} type={`${respondent}_${grade.code}`}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <div className={style.gradeHeader}>
                                <div className={style.title}>{grade.label}</div>
                                {!isSame && (
                                    <div className={style.time}>
                                        Total estimated time: <IconClock spaceLeft spaceRight />
                                        <strong>{duration} minutes</strong>
                                    </div>
                                )}
                            </div>
                            <div className={style.measuresWrapper}>
                                <div className={style.measures}>
                                    {sameAs ? (
                                        <div className="checkbox" style={{ margin: 0 }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={`${respondent}_${grade.code}_same`}
                                                    value="true"
                                                    checked={isSame}
                                                    onChange={() => {
                                                        if (isSame) {
                                                            copyMeasures(respondent, grade.code);
                                                        } else {
                                                            setFieldValue('measures', {
                                                                ...values.measures,
                                                                [respondent]: {
                                                                    ...values.measures[respondent],
                                                                    [grade.code]: sameAs,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />{' '}
                                                The same as {gradeMap[sameAs]}
                                            </label>
                                        </div>
                                    ) : null}

                                    {!isSame && measures.length === 0 ? (
                                        <div>There are currently no measures selected</div>
                                    ) : null}
                                    {!isSame &&
                                        measures.map((measureCode, index) => {
                                            const measure = measureMap[measureCode];

                                            return (
                                                <Draggable key={measureCode} draggableId={measureCode} index={index}>
                                                    {(provided1, snapshot1) => (
                                                        <div
                                                            ref={provided1.innerRef}
                                                            {...provided1.draggableProps}
                                                            className={style.measure}
                                                        >
                                                            <div
                                                                className={style.remove}
                                                                onClick={() =>
                                                                    removeFromLevel(respondent, grade.code, measureCode)
                                                                }
                                                            >
                                                                <IconCross />
                                                            </div>
                                                            <div
                                                                className={style.sortHandle}
                                                                {...provided1.dragHandleProps}
                                                            >
                                                                <IconArrowResize />
                                                            </div>
                                                            <div className={style.content}>
                                                                <div className={style.title}>
                                                                    {measure.pdf ? (
                                                                        <a
                                                                            href={measure.pdf}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {measure.label}
                                                                        </a>
                                                                    ) : (
                                                                        measure.label
                                                                    )}
                                                                </div>
                                                                <div className={style.grid}>
                                                                    <div>
                                                                        <IconClock spaceRight />
                                                                        {measure.duration} minutes
                                                                    </div>
                                                                    <div className={style.skill}>
                                                                        <div className={style.skillIcon} />
                                                                        {measure.area
                                                                            .map(a => skillMap[a].label)
                                                                            .join(', ')}
                                                                    </div>
                                                                    <div>
                                                                        <IconEarth spaceRight />
                                                                        {measure.language
                                                                            .map(l => languageMap[l])
                                                                            .join(', ')}
                                                                    </div>
                                                                    <div>
                                                                        <IconClipboard spaceRight />
                                                                        {typeMap[measure.type]}
                                                                    </div>
                                                                    <div>
                                                                        <IconUser spaceRight />
                                                                        {respondentMap[measure.respondent]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    {provided.placeholder}
                                </div>
                                {!isSame && (
                                    <Modal
                                        trigger={
                                            <button type="button" className="btn btn-primary">
                                                Customize
                                            </button>
                                        }
                                        title="Customize"
                                        size="large"
                                    >
                                        <AddNewMeasure
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            respondent={respondent}
                                            grade={grade.code}
                                            typeMap={typeMap}
                                            languageMap={languageMap}
                                            respondentMap={respondentMap}
                                        />
                                    </Modal>
                                )}
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const renderRespondent = respondentInfo => {
        const respondent = respondentInfo.value;

        const respondentGrades = data.gradeLevels.filter(item => values.respondents[respondent].includes(item.code));

        return (
            <div key={respondent}>
                <div className={style.respondentHeader}>
                    {respondentInfo.icon} {respondentInfo.label}
                </div>
                <div className={style.respondent} style={{ backgroundColor: respondentInfo.color }}>
                    {respondentGrades.map(grade => renderGroupMeasures(respondent, grade))}
                </div>
            </div>
        );
    };

    const actualRespondents = respondentOptions.filter(item => values.respondents[item.value].length > 0);

    return (
        <div>
            <div className={'alert alert-success ' + style.alert}>
                <IconInfo />
                <div>
                    We&apos;ve recommended some measures for you based on your choices.
                    <br />
                    Please review, edit as needed, and create the assessment.
                </div>
            </div>

            <AssesmentChoices {...props} />

            <div className={style.header}>
                <IconCheck spaceRight />
                Recommended Measures
            </div>

            <div className={style.respondentWrapper}>{actualRespondents.map(renderRespondent)}</div>
        </div>
    );
};

Measures.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Measures;
