import React from 'react';
import style from './style.css';
import { NavbarLink } from 'cccisd-header';
import { useHistory } from 'cccisd-react-router';
import IconList from 'cccisd-icons/list';
import IconFolder from 'cccisd-icons/folder-open';
import IconCog from 'cccisd-icons/cog2';

const Fortress = window.cccisd.fortress;
const roles = ['uberadmin', 'orgAdmin', 'guAdmin', 'supervisor', 'siteAdmin', 'instructor'];
const userRole = Fortress.user.acting.role.handle;

const SecondaryNav = () => {
    const history = useHistory();
    const hasManage = roles.includes(Fortress.user.acting.data_type);

    return (
        <div className={style.secondaryNav}>
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#secondaryNav"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                    </div>

                    <div className="collapse navbar-collapse" id="secondaryNav">
                        <ul className="nav navbar-nav">
                            {userRole === 'instructor' && (
                                <NavbarLink to="/administration" highlight="administration">
                                    <IconList spaceRight />
                                    Admin Dashboard
                                </NavbarLink>
                            )}
                            <li
                                className={`dropdown ${
                                    history.location.pathname.includes('/resource-centers') ? 'active' : ''
                                }`}
                            >
                                <a
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <IconFolder spaceRight />
                                    Resources <span className="caret" />
                                </a>
                                <ul className="dropdown-menu">
                                    <NavbarLink
                                        to="/resource-centers/surveybank"
                                        highlight="/resource-centers/surveybank"
                                    >
                                        Instrument Library
                                    </NavbarLink>
                                    <NavbarLink to="/resource-centers/lessons" highlight="/resource-centers/lessons">
                                        Lesson Plans
                                    </NavbarLink>
                                    <NavbarLink to="/resource-centers/pd" highlight="/resource-centers/pd">
                                        Professional Development
                                    </NavbarLink>
                                </ul>
                            </li>
                            {hasManage && (
                                <NavbarLink to="/manage" highlight="manage">
                                    <IconCog spaceRight />
                                    Manage
                                </NavbarLink>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default SecondaryNav;
