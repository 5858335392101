import React from 'react';
import IconStudents from 'cccisd-icons/users4';
import IconTeacher from 'cccisd-icons/user';

export const respondentOptions = [
    {
        value: 'students',
        label: 'Students',
        durationLabel: (
            <div>
                <b>Students</b> (time per administration)
            </div>
        ),
        durationTime: 'Maximum time to spend on assessment per administration',
        icon: <IconStudents />,
        timeOptions: [
            { value: 15, label: '15 minutes' },
            { value: 30, label: '30 minutes' },
            { value: 45, label: '45 minutes' },
            { value: 60, label: '60 minutes' },
        ],
        color: 'hsl(200, 65%, 91%)',
    },
    {
        value: 'teachers',
        label: 'Teachers or other school staff',
        durationLabel: (
            <div>
                <b>Teachers/School Staff</b> (time per student)
            </div>
        ),
        durationTime: 'Maximum time to spend on assessment per student',
        icon: <IconTeacher />,
        timeOptions: [
            { value: 1, label: '1 minute' },
            { value: 5, label: '5 minutes' },
            { value: 10, label: '10 minutes' },
        ],
        color: 'hsl(249, 65%, 91%)',
    },
];

const data = {
    gradeLevels: [
        { code: 'K', label: 'Kindergarten', band: 'earlyElementary' },
        { code: '1', label: '1st Grade', band: 'earlyElementary' },
        { code: '2', label: '2nd Grade', band: 'earlyElementary' },
        { code: '3', label: '3rd Grade', band: 'middleElementary' },
        { code: '4', label: '4th Grade', band: 'middleElementary' },
        { code: '5', label: '5th Grade', band: 'lateElementary' },
        { code: '6', label: '6th Grade', band: 'lateElementary' },
        { code: '7', label: '7th Grade', band: 'adolescent' },
        { code: '8', label: '8th Grade', band: 'adolescent' },
        { code: '9', label: '9th Grade', band: 'adolescent' },
        { code: '10', label: '10th Grade', band: 'adolescent' },
        { code: '11', label: '11th Grade', band: 'adolescent' },
        { code: '12', label: '12th Grade', band: 'adolescent' },
    ],
    gradeBands: [
        { code: 'earlyElementary', label: 'Early Elementary', description: 'Grades K - 2' },
        { code: 'middleElementary', label: 'Middle Elementary', description: 'Grades 3 - 4' },
        { code: 'lateElementary', label: 'Late Elementary', description: 'Grades 5 - 6' },
        { code: 'adolescent', label: 'Adolescent', description: 'Grades 7 - 12' },
    ],
    measures: [
        {
            code: 'm1',
            label: 'Child Social Achievement Goals Measure',
            area: ['SoA'],
            skills: ['positive-relationships'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 8,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7f/7f5df9aeac4b38690b6bcfb35c7cf1c5.pdf',
        },
        {
            code: 'm2',
            label: 'CORE Districts SEL Survey: Self-efficacy',
            area: ['SeA'],
            skills: ['self-efficacy'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 2,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/88/888876599226632f22143700092d7009.pdf',
        },
        {
            code: 'm3',
            label: 'CORE Districts Growth Mindset subscale (Formerly Growth Mindset Scale)',
            area: ['SoA'],
            skills: ['growth-mindset'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 2,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/4f/4f916cce54e8576ffefed22a712cc579.pdf',
        },
        {
            code: 'm4',
            label: 'CORE Districts SEL Survey: Self-Management',
            area: ['SM'],
            skills: ['self-discipline', 'self-motivation', 'emotion-regulation', 'impulse-control'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ee/ee54b5856b3b06147950d5e328ae7baf.pdf',
        },
        {
            code: 'm5',
            label: 'CORE Districts: Social Awareness Scale',
            area: ['SoA'],
            skills: ['emotion-expression', 'emotion-recognition'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/aa/aa1e4ff32f344d56fd6e9b33e7912d20.pdf',
        },
        {
            code: 'm6',
            label: 'Delaware Social-Emotional Competency Scale (DSECS-S)',
            area: [],
            skills: [],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 5,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/0a/0a574a7f85c372a41facd667bb6767e2.pdf',
        },
        {
            code: 'm7',
            label: 'Emotion Expression Scale for Children',
            area: ['RS'],
            skills: ['emotion-expression'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 4.5,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/cc/cc1a7a323c872a979fce8a398df8d5a4.pdf',
        },
        {
            code: 'm8',
            label: 'Emotion Regulation Index for Children and Adolescents',
            area: ['SeA', 'SM'],
            skills: ['emotion-regulation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 5,
            grades: ['4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/9f/9f04eaa53bcaec9d0bec3c01efadc307.pdf',
        },
        {
            code: 'm9',
            label: 'Empathy Questionnaire',
            area: ['RS'],
            skills: ['empathy'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 5,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/2c/2c2b1be276af57784c7d4f71f87472bb.pdf',
        },
        {
            code: 'm10',
            label: 'Metacognitive Self-Regulation',
            area: ['SM'],
            skills: ['self-motivation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 4.5,
            grades: ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/e5/e58f87df2bcec7a7f9094dd54d269096.pdf',
        },
        {
            code: 'm11',
            label: 'Personal and Academic Self-Concept Inventory (PASCI)',
            area: ['SeA', 'RS'],
            skills: ['self-esteem', 'self-evaluation', 'assertiveness'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 8,
            grades: ['9', '10', '11', '12'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/8e/8e17d32a4e18b4913a3faad65dd5e1cb.pdf',
        },
        {
            code: 'm12',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Self-Regard Subscale',
            area: ['SeA'],
            skills: ['self-esteem', 'self-evaluation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 2,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/e6/e63b921f5793eebd3a6f6b3c8e848e34.pdf',
        },
        {
            code: 'm13',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Social Acceptance Subscale',
            area: ['SeA'],
            skills: ['self-esteem', 'self-evaluation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/81/81ad5db294dc65a2a418ccf94219e7d6.pdf',
        },
        {
            code: 'm14',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Math Ability Subscale',
            area: ['SeA'],
            skills: ['self-esteem', 'self-evaluation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/c8/c8154a7b0b05b99a4158e81e65ada60d.pdf',
        },
        {
            code: 'm15',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Verbal Ability Subscale',
            area: ['SeA'],
            skills: ['self-esteem', 'self-evaluation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7d/7d6c26c4b9b3181113d7f62b2e6b8475.pdf',
        },
        {
            code: 'm16',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Physical Appearance Subscale',
            area: ['SeA'],
            skills: ['self-esteem'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/26/26bb47d5896b89dc4c7f3e0effaed9f8.pdf',
        },
        {
            code: 'm17',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Physical Ability Subscale',
            area: ['SeA'],
            skills: ['self-esteem', 'self-evaluation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/61/61d4b54e506dd49ac278e53fb743eaf3.pdf',
        },
        {
            code: 'm18',
            label: 'Personal and Academic Self-Concept Inventory (PASCI) - Social Anxiety Subscale',
            area: ['RS'],
            skills: ['assertiveness'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/45/457bde4be032e544c1f37b3843f41fc8.pdf',
        },
        {
            code: 'm19',
            label: 'Questionnaire on Self-Regulation',
            area: ['SM'],
            skills: ['emotion-regulation', 'goal-setting'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 2,
            grades: ['K', '1', '2'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/83/83633ca1e11e435054ebb3029d5f1d19.pdf',
        },
        {
            code: 'm20',
            label: 'SDQ Self-assessment (Peer Problems Subscale)',
            area: ['RS'],
            skills: ['positive-relationships'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/35/357e72ab0383a83e5834b0cacc122e89.pdf',
        },
        {
            code: 'm21',
            label: 'SDQ Self-assessment (Prosocial Subscale)',
            area: ['RS'],
            skills: ['compassion'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/d3/d3ca3800755dbd8a56b387fb4d63a6ed.pdf',
        },
        {
            code: 'm22',
            label: 'SDQ Teacher assessment (Peer Problems Subscale) (K-5)',
            area: ['RS'],
            skills: ['positive-relationships'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en', 'es'],
            duration: 1,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/af/af8ebeb61944817b8f1582e1677d68cb.pdf',
        },
        {
            code: 'm23',
            label: 'SDQ Teacher assessment (Peer Problems Subscale) (6-12)',
            area: ['RS'],
            skills: ['positive-relationships'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en', 'es'],
            duration: 1,
            grades: ['6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/af/af8ebeb61944817b8f1582e1677d68cb.pdf',
        },
        {
            code: 'm24',
            label: 'SDQ Teacher assessment (Prosocial Subscale) (K-5)',
            area: ['RS'],
            skills: ['compassion'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en', 'es'],
            duration: 1,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/c7/c73607ea324ee8f50dd54449a010f217.pdf',
        },
        {
            code: 'm25',
            label: 'SDQ Teacher assessment (Prosocial Subscale) (6-12)',
            area: ['RS'],
            skills: ['compassion'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en', 'es'],
            duration: 1,
            grades: ['6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/c7/c73607ea324ee8f50dd54449a010f217.pdf',
        },
        {
            code: 'm26',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Emotion Management Subscale',
            area: ['SeA', 'SM'],
            skills: ['emotion-regulation', 'emotion-recognition'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/e9/e93f9c7d9309e0434a7c6f9df98a68e8.pdf',
        },
        {
            code: 'm27',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Empathy Subscale',
            area: ['SoA'],
            skills: ['empathy'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/32/32d9da18e29fdbf7e7bad30869b0be37.pdf',
        },
        {
            code: 'm28',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Initiative Subscale',
            area: ['SeA', 'SM'],
            skills: ['growth-mindset', 'self-efficacy'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/88/8881bd9460f2db11c6e94d5ef7c73918.pdf',
        },
        {
            code: 'm29',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Problem-Solving Subscale',
            area: ['RDM'],
            skills: ['social-problem-solving'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7e/7e9d98eedb1805ad71124e8d7c99fde0.pdf',
        },
        {
            code: 'm30',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Responsibility Subscale',
            area: ['SeA'],
            skills: ['self-motivation'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7e/7e9d98eedb1805ad71124e8d7c99fde0.pdf',
        },
        {
            code: 'm31',
            label: 'SEL Strengths Builder [Staff Rating of Youth Behavior (SRYB)] - Teamwork Subscale',
            area: ['RS'],
            skills: ['cooperation'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 7,
            grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/60/600d91b91e9d1db470873e0ca8c98fb8.pdf',
        },
        {
            code: 'm32',
            label: 'SELweb EE Overall Social Competence',
            area: [],
            skills: ['emotion-recognition', 'impulse-control', 'perspective-taking', 'social-problem-solving'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 30,
            grades: ['K', '1', '2'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7c/7c13543dde5cb4c3c1069b26dc665357.pdf',
        },
        {
            code: 'm33',
            label: 'SELweb EE Emotion Recognition Task',
            area: ['SeA'],
            skills: ['emotion-recognition'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 4,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/25/2547493ac6dbc316d5aed0a0ae618fad.pdf',
        },
        {
            code: 'm34',
            label: 'SELweb EE Self-Control Modules',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/6b/6ba7d644c9205fb5355443035c7c7ee4.pdf',
        },
        {
            code: 'm35',
            label: 'SELweb EE Self-Control: Choice Delay',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 4,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm36',
            label: 'SELweb EE Self-Control: Frustration Tolerance',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 3,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm37',
            label: 'SELweb EE Social Perspective Taking',
            area: ['SoA'],
            skills: ['perspective-taking'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 6,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/8a/8a2ad9b2f15e2c37b9e1edbd4422566a.pdf',
        },
        {
            code: 'm38',
            label: 'SELweb EE Social Problem Solving',
            area: ['RS', 'RDM'],
            skills: ['social-problem-solving'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 6,
            grades: ['K', '1', '2', '3'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/c9/c92f082932a76e80570fa3d23e41f38e.pdf',
        },
        {
            code: 'm39',
            label: 'SELweb LE Overall Social Competence',
            area: [],
            skills: ['emotion-recognition', 'impulse-control', 'perspective-taking', 'social-problem-solving'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 30,
            grades: ['4', '5', '6'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/57/57eeeaa3d4aefaf3d2073f15655e3861.pdf',
        },
        {
            code: 'm40',
            label: 'SELweb LE Causes Solutions Consequences',
            area: ['RS', 'RDM'],
            skills: ['social-problem-solving'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 6,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/77/7763cd1a65eafbcf8f2d7e97a623bd5c.pdf',
        },
        {
            code: 'm41',
            label: 'SELweb LE Complex Emotion Recognition',
            area: ['SM'],
            skills: ['emotion-recognition'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 8,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm42',
            label: 'SELweb LE Self Control Modules',
            area: ['SM'],
            skills: ['emotion-recognition', 'impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/3a/3ac5b437d0d90f334dd43b118b2ff5bb.pdf',
        },
        {
            code: 'm43',
            label: 'SELweb LE Emotion Regulation Knowledge',
            area: ['SM'],
            skills: ['emotion-regulation'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 8,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm44',
            label: 'SELweb LE Self-Awareness',
            area: ['SeA'],
            skills: ['self-evaluation'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/a3/a37bf9f25c711f3b3c183da859648565.pdf',
        },
        {
            code: 'm45',
            label: 'SELweb LE Self-Reported Self-Control',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 4,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm46',
            label: 'SELweb LE Social Perspective-Taking',
            area: ['SoA'],
            skills: ['perspective-taking'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 8,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: '',
        },
        {
            code: 'm47',
            label: 'SELweb LE Understanding Others Module',
            area: ['SoA'],
            skills: ['emotion-recognition', 'perspective-taking'],
            type: 'direct',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 16,
            grades: ['4', '5', '6'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/b5/b597d117413db84ec2bfd6c41132a65e.pdf',
        },
        {
            code: 'm48',
            label: 'Tauck Family Foundation Formative Assessment Tool - student survey',
            area: ['SeA', 'SM', 'SoA'],
            skills: ['self-efficacy', 'impulse-control', 'growth-mindset', 'self-motivation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 4,
            grades: ['3', '4', '5'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/04/04b4bee273d879a147a92a9c3600b9ed.pdf',
        },
        {
            code: 'm49',
            label: 'Tauck Family Foundation Formative Assessment Tool - student survey - Self-control subscale',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/80/800f208cd2fce9f8d89acd15b42753df.pdf',
        },
        {
            code: 'm50',
            label: 'Tauck Family Foundation Formative Assessment Tool - student survey - Self-efficacy subscale',
            area: ['SeA'],
            skills: ['self-efficacy'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/c5/c57e1f1ba29be096ad7ed0521c0fce1d.pdf',
        },
        {
            code: 'm51',
            label: 'Tauck Family Foundation Formative Assessment Tool - student survey - Persistence subscale',
            area: ['SeA'],
            skills: ['growth-mindset'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/26/262cde1d23202ead0ed776dbaaeef4ef.pdf',
        },
        {
            code: 'm52',
            label: 'Tauck Family Foundation Formative Assessment Tool - student survey - Mastery orientation subscale',
            area: ['SM'],
            skills: ['self-motivation'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 1,
            grades: ['3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/07/07eb2b83b8aeded667db3424908a7237.pdf',
        },
        {
            code: 'm53',
            label: 'Tauck Family Foundation Formative Assessment Tool - teacher survey',
            area: ['SeA', 'SM', 'SoA', 'RS'],
            skills: [
                'self-discipline',
                'growth-mindset',
                'impulse-control',
                'cooperation',
                'conflict-resolution',
                'empathy',
            ],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 4,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/0d/0d4959dce9c404519c10aeff45200002.pdf',
        },
        {
            code: 'm54',
            label: 'Tauck Family Foundation Formative Assessment Tool - teacher survey - Persistence',
            area: ['SeA', 'SM'],
            skills: ['self-discipline', 'growth-mindset'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 1,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/59/59fa4e83499e800ca9a7f42ddf802cc4.pdf',
        },
        {
            code: 'm55',
            label: 'Tauck Family Foundation Formative Assessment Tool - teacher survey - Self-control',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 1,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ac/acb5c675eb1069341a7221b4b7b273d3.pdf',
        },
        {
            code: 'm56',
            label: 'Tauck Family Foundation Formative Assessment Tool - teacher survey - Social competence',
            area: ['RS', 'SeA'],
            skills: ['cooperation', 'conflict-resolution', 'empathy'],
            type: 'survey',
            respondent: 'teachers',
            language: ['en'],
            duration: 2,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/fa/fae1ebc29b21093646cee8b6f367167f.pdf',
        },
        {
            code: 'm57',
            label: 'Washoe: Emotion Knowledge Scale',
            area: ['SeA'],
            skills: ['emotion-knowledge'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/53/535cbf9d22f788d6ce885b618ad14ec1.pdf',
        },
        {
            code: 'm58',
            label: 'Washoe: Emotion Regulation',
            area: ['SM'],
            skills: ['emotion-regulation'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/97/97a89b38ad7fc97115df6940f0e022a9.pdf',
        },
        {
            code: 'm59',
            label: 'Washoe: Goal Management',
            area: ['SM'],
            skills: ['goal-setting'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/01/01d35ac6a61c7eeeffa42c42a0cc53b4.pdf',
        },
        {
            code: 'm60',
            label: 'Washoe: School Work',
            area: ['SM'],
            skills: ['self-motivation'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ff/ffad71b662cf4ff35adcbce480e3bc98.pdf',
        },
        {
            code: 'm61',
            label: 'Washoe: Relationship Skills',
            area: ['RS'],
            skills: ['conflict-resolution', 'cooperation', 'communication'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/7d/7d38c4e2dade205b08a646579701e8d2.pdf',
        },
        {
            code: 'm62',
            label: 'Washoe: Responsible Decision-Making',
            area: ['RDM'],
            skills: ['understanding-consequences', 'prosocial', 'social-problem-solving'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ed/ed8ceb63997c79b48cea6723c318f591.pdf',
        },
        {
            code: 'm63',
            label: 'Washoe: Self-Concept Scale',
            area: ['SeA'],
            skills: ['self-evaluation', 'self-esteem', 'growth-mindset'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ae/ae2748da8fee2f4d4d013fc75ca8bff2.pdf',
        },
        {
            code: 'm64',
            label: 'Washoe: Short Form (17 items)',
            area: [],
            skills: [],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/2a/2ad7044333e303698f173368bc642bdb.pdf',
        },
        {
            code: 'm65',
            label: 'Washoe: Social Awareness Scale',
            area: ['SoA'],
            skills: ['emotion-recognition'],
            type: 'survey',
            respondent: 'students',
            language: ['en', 'es'],
            duration: 1,
            grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/b5/b5378e8e99c4c7837b92273000c32c03.pdf',
        },
        {
            code: 'm66',
            label: 'Youth Life Skills Evaluation: Communication',
            area: ['RS'],
            skills: ['communication'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 10,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/67/679184c0302927d02b1b2b5fa505f512.pdf',
        },
        {
            code: 'm67',
            label: 'Youth Life Skills Evaluation: Critical Thinking',
            area: ['RDM'],
            skills: ['critical-thinking'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 8,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/d0/d0ee1b7fac32db762a7862a4a6d1db01.pdf',
        },
        {
            code: 'm68',
            label: 'Youth Life Skills Evaluation: Decision-Making',
            area: ['RDM'],
            skills: ['understanding-consequences', 'social-problem-solving', 'critical-thinking'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 14,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/92/923e0a527442f9dfc1b56f1716261c12.pdf',
        },
        {
            code: 'm69',
            label: 'Youth Life Skills Evaluation: Goal Setting',
            area: ['SM'],
            skills: ['goal-setting'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 8,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/f1/f131912879ff260388da09cf9fe5efba.pdf',
        },
        {
            code: 'm70',
            label: 'Youth Life Skills Evaluation: Overall Short Form',
            area: ['RDM', 'SM', 'RS'],
            skills: ['Goal Setting', 'critical-thinking', 'social-problem-solving', 'goal-setting', 'communication'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 11,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/b8/b80b774dbf55186b775c9237bf103137.pdf',
        },
        {
            code: 'm71',
            label: 'Youth Life Skills Evaluation: Problem-Solving',
            area: ['RDM'],
            skills: ['social-problem-solving'],
            type: 'survey',
            respondent: 'students',
            language: ['en'],
            duration: 10,
            grades: ['7', '8', '9', '10', '11', '12'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/35/357e72ab0383a83e5834b0cacc122e89.pdf',
        },
        {
            code: 'm72',
            label: 'Zoo U - Communication Scene',
            area: ['RS'],
            skills: ['communication'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/aa/aa32d69eb87e3175163a807568ee50ef.pdf',
        },
        {
            code: 'm73',
            label: 'Zoo U - Cooperation Scene',
            area: ['RS'],
            skills: ['cooperation'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/37/370eed83b37d6eb4df2d7bdbfb9931d4.pdf',
        },
        {
            code: 'm74',
            label: 'Zoo U - Emotion Regulation Scene',
            area: ['SM'],
            skills: ['emotion-regulation'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/dc/dcd8993c549528abbf104b2b6f68a879.pdf',
        },
        {
            code: 'm75',
            label: 'Zoo U - Empathy Scene',
            area: ['SoA'],
            skills: ['empathy'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/a7/a71e3cfe691afc71e442185b95297c07.pdf',
        },
        {
            code: 'm76',
            label: 'Zoo U - Impulse Control Scene',
            area: ['SM'],
            skills: ['impulse-control'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/ca/ca5797e83369acd7f8e64fb668461e4d.pdf',
        },
        {
            code: 'm77',
            label: 'Zoo U - Social Initiation Scene',
            area: ['RDM'],
            skills: ['social-initiation'],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 3.5,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: false,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/07/07ae0c6e118697e5b4481bff09199cfd.pdf',
        },
        {
            code: 'm78',
            label: 'Zoo U -Overall Social Competence',
            area: ['RS', 'SM', 'SoA', 'RDM'],
            skills: [
                'social-problem-solving',
                'communication',
                'cooperation',
                'emotion-regulation',
                'empathy',
                'impulse-control',
                'social-initiation',
            ],
            type: 'direct',
            respondent: 'students',
            language: ['en'],
            duration: 21,
            grades: ['K', '1', '2', '3', '4', '5'],
            overall: true,
            pdf: 'https://dm0gz550769cd.cloudfront.net/selquest/46/461695b005c0fd570d7ec0544bb57099.pdf',
        },
    ],
    skills: [
        {
            code: 'SM',
            label: 'Self-Management',
            list: [
                {
                    code: 'courage',
                    label: 'Courage',
                    description: 'Taking positive action in spite of fear',
                },
                {
                    code: 'emotion-regulation',
                    label: 'Emotion Regulation',
                    description: 'Identifying and managing your own emotions',
                },
                {
                    code: 'goal-setting',
                    label: 'Goal-setting',
                    description: 'Choosing personal and collective outcomes to work toward',
                },
                {
                    code: 'impulse-control',
                    label: 'Impulse Control',
                    description: 'Controlling your own behavior',
                },
                {
                    code: 'organizational-skills',
                    label: 'Organizational Skills',
                    description: 'Planning and structuring activities to maximize efficiency and goal completion',
                },
                {
                    code: 'self-discipline',
                    label: 'Self-discipline',
                    description:
                        'Taking actions that align with personal goals and resisting actions that interfere with goals',
                },
                {
                    code: 'self-motivation',
                    label: 'Self-motivation',
                    description:
                        'Prompting oneself to working toward personal or collective goals regardless of external rewards',
                },
                {
                    code: 'stress-management',
                    label: 'Stress Management',
                    description: "Using strategies to reduce one's level of stress and anxiety",
                },
            ],
        },
        {
            code: 'SeA',
            label: 'Self-Awareness',
            list: [
                {
                    code: 'emotion-knowledge',
                    label: 'Emotion Knowledge',
                    description: 'Accurately identifying emotional states from situational or contextual cues',
                },
                {
                    code: 'growth-mindset',
                    label: 'Growth Mindset',
                    description: 'Believing one is capable of continuous self-improvement',
                },
                {
                    code: 'honesty',
                    label: 'Honesty',
                    description: 'Being truthful and honest with others',
                },
                {
                    code: 'self-efficacy',
                    label: 'Self-efficacy',
                    description: 'Believing one is capable of achieving a desired goal or result',
                },
                {
                    code: 'self-esteem',
                    label: 'Self-esteem',
                    description: "Having positive regard for oneself and belief in one's self worth",
                },
                {
                    code: 'self-evaluation',
                    label: 'Self-evaluation',
                    description: "Examining one's own beliefs and evaluating possible prejudices and biases in oneself",
                },
            ],
        },
        {
            code: 'SoA',
            label: 'Social Awareness',
            list: [
                {
                    code: 'compassion',
                    label: 'Compassion',
                    description: 'Showing concern and caring for others',
                },
                {
                    code: 'emotion-expression',
                    label: 'Emotion Expression',
                    description: "Communicating one's emotions to others",
                },
                {
                    code: 'emotion-recognition',
                    label: 'Emotion Recognition',
                    description: 'Accurately identifying emotions in oneself',
                },
                {
                    code: 'empathy',
                    label: 'Empathy',
                    description: 'Understanding and sharing the feelings of another',
                },
                {
                    code: 'gratitude',
                    label: 'Gratitude',
                    description: 'Showing appreciation and thankfulness',
                },
                {
                    code: 'perspective-taking',
                    label: 'Perspective Taking',
                    description: "Understanding a situation from another person's point of view",
                },
                {
                    code: 'situational-demands',
                    label: 'Understanding situational demands',
                    description: 'Recognizing challenges and opportunities in a situation',
                },
            ],
        },
        {
            code: 'RS',
            label: 'Relationship Skills',
            list: [
                {
                    code: 'assertiveness',
                    label: 'Assertiveness',
                    description: 'Advocating for oneself and standing up for oneself or others',
                },
                {
                    code: 'collaboration',
                    label: 'Collaboration',
                    description: 'Working together to solve a problem or achieve a goal',
                },
                {
                    code: 'communication',
                    label: 'Communication',
                    description: 'What you say and how you say it',
                },
                {
                    code: 'conflict-resolution',
                    label: 'Conflict Resolution',
                    description: 'Finding a peaceful way to resolve disagreements',
                },
                {
                    code: 'cooperation',
                    label: 'Cooperation',
                    description: 'Working together with others as a team',
                },
                {
                    code: 'cultural-competence',
                    label: 'Cultural Competence',
                    description: 'Understanding and appreciating people from a variety of cultures or backgrounds',
                },
                {
                    code: 'positive-relationships',
                    label: 'Developing Positive Relationships',
                    description: 'Building mutually supportive connections with others and positive social supports',
                },
                {
                    code: 'leadership',
                    label: 'Leadership',
                    description: 'Positively influencing and guiding the actions of others',
                },
                {
                    code: 'listening',
                    label: 'Listening',
                    description: 'Attending to and seeking to understand what others are saying',
                },
                {
                    code: 'resisting-peer-pressure',
                    label: 'Resisting Peer Pressure',
                    description: "Acting on one's own beliefs regardless of negative influences by others",
                },
            ],
        },
        {
            code: 'RDM',
            label: 'Responsible Decision Making',
            list: [
                {
                    code: 'critical-thinking',
                    label: 'Critical Thinking',
                    description:
                        'Objectively conceptualizing, analyzing, and evaluating information to form a judgment or conclusion',
                },
                {
                    code: 'curiosity',
                    label: 'Curiosity',
                    description: 'Wanting to learn and understand the world around oneself',
                },
                {
                    code: 'open-mindedness',
                    label: 'Open-mindedness',
                    description: 'Being receptive to a variety of differing ideas and open to different points of view',
                },
                {
                    code: 'prosocial',
                    label: 'Prosocial',
                    description: 'Acting in ways to promote the well-being of others',
                },
                {
                    code: 'reasoned-judgments',
                    label: 'Reasoned judgments',
                    description: 'Using information, data, and facts to come to a conclusion or decision',
                },
                {
                    code: 'social-initiation',
                    label: 'Social Initiation',
                    description: 'Wanting to and understanding how to play and work well with others',
                },
                {
                    code: 'social-problem-solving',
                    label: 'Social problem-solving',
                    description: 'Developing a plan of action to effectively resolve personal or social problems',
                },
                {
                    code: 'understanding-consequences',
                    label: 'Understanding Consequences',
                    description:
                        "Recognizing and evaluating how one's actions will impact the situation and others, both in the short- and long-term",
                },
            ],
        },
    ],
};

export default data;
