import React from 'react';
import { useParams } from 'cccisd-react-router';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

const ResourceCenters = () => {
    const { handle } = useParams();

    function renderResourceTiles(data, renderDefault) {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    }

    return (
        <div className={style.resourceCenter}>
            <ResourceCenter handle={handle} renderResourceTiles={renderResourceTiles} />
        </div>
    );
};

export default ResourceCenters;
