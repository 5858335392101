import React from 'react';
import PropTypes from 'prop-types';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconCross from 'cccisd-icons/cancel-circle2';
import snapshotIcon from './snapshot.png';
import growthIcon from './growth.png';
import classnames from 'classnames';
import style from './style.css';

export const purposeOptions = [
    {
        value: 'snapshot',
        label: 'Snapshot',
        description: 'Examine current SEL competences for students',
        icon: snapshotIcon,
        pros: ['Only requires assessing once'],
        cons: ['Cannot inform student growth/progress'],
    },
    {
        value: 'growth',
        label: 'Growth',
        description: "Examine whether students' responses to the same set of measures change over time.",
        icon: growthIcon,
        pros: ['Can inform student growth/progress'],
        cons: ['Requires assessing at least twice'],
    },
];

const Purpose = props => {
    const { values, setFieldValue } = props;

    return (
        <div className={style.wrapper}>
            {purposeOptions.map(item => (
                <div
                    key={item.value}
                    className={classnames(style.block, {
                        [style.selected]: item.value === values.purpose,
                    })}
                    onClick={() => {
                        setFieldValue('purpose', item.value);
                        setFieldValue('timepoints', values.timepoints.slice(0, item.value === 'snapshot' ? 1 : 2));
                    }}
                >
                    <div className={style.header}>
                        <div className={style.radio} />
                        <div className={style.icon} style={{ backgroundImage: `url("${item.icon}")` }} />
                        <div className={style.title}>
                            <b>{item.label}</b>
                        </div>
                        <div className={style.description}>{item.description}</div>
                    </div>
                    <div className={style.prosCons}>
                        <div className={style.pros + ' alert alert-success'}>
                            <div className={style.title}>Pros</div>
                            <div className={style.list}>
                                {item.pros.map((str, index) => (
                                    <div key={index}>
                                        <IconCheck />
                                        {str}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={style.cons + ' alert alert-danger'}>
                            <div className={style.title}>Cons</div>
                            <div className={style.list}>
                                {item.cons.map((str, index) => (
                                    <div key={index}>
                                        <IconCross />
                                        {str}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

Purpose.propTypes = {
    stageInfo: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default Purpose;
